import { Box, Collapse } from '@mui/material';
import { LoginPhases, useLogIn } from 'lib/hooks/useLogIn';
import { useParseQueryString } from 'lib/hooks/useParseQueryString';
import { UserContext } from 'lib/UserContext';
import { FC, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Page from '../../Page';
import TwoFactorTokenUi from './TwoFactorTokenUi';
import UsernamePasswordUi from './UsernamePasswordUi';

export interface QueryParams {
  returnUrl?: string;
}

const Login: FC = () => {
  const { isLoggedIn } = useContext(UserContext);
  const [rememberMe, setRememberMe] = useState(false);

  const { loading, logIn, phase, setPhase, submitYubiKeyOtp } = useLogIn();

  const params = useParseQueryString<QueryParams>();

  if (isLoggedIn) {
    if (params.returnUrl) {
      return <Navigate to={params.returnUrl} replace />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return (
    <Page title="Log In" requireAuth={false} showAppBar={false}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Collapse in={phase === LoginPhases.UsernamePassword}>
            <UsernamePasswordUi
              logIn={logIn}
              loading={loading}
              rememberMe={rememberMe}
              setRememberMe={setRememberMe}
            />
          </Collapse>
          <Collapse in={phase === LoginPhases.MFA}>
            <TwoFactorTokenUi
              setPhase={setPhase}
              submitYubiKeyOtp={submitYubiKeyOtp}
              rememberMe={rememberMe}
              loading={loading}
              isPhaseMfa={phase === LoginPhases.MFA}
            />
          </Collapse>
        </Box>
      </Box>
    </Page>
  );
};

export default Login;
