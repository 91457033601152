import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFound from '../../not_found.png';
import { Typography, Button } from '@mui/material';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '40px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '50%',
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Houston, we've had a problem here.
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            That's a 404.
          </Typography>
          <Typography variant="h5" sx={{ mb: 2 }}>
            We searched the galaxy but couldn't find that page anywhere.
          </Typography>

          <div style={{ marginTop: '30px' }}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
          </div>
        </div>
        <div
          style={{
            display: 'relative',
          }}
        >
          <img
            style={{
              width: '100%',
              maxWidth: '800px',
            }}
            alt="Not found"
            src={NotFound}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
