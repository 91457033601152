import { useMutation, useQuery } from '@apollo/client';
import { Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, MouseEventHandler, useCallback, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PartialFragment } from 'lib/gql/typeHelpers';
import EditPageCard from 'lib/components/EditPageCard';
import { extractError } from 'lib/gql/extractError';
import { dateTimeFormat } from '../../../lib/formatters';
import Page from '../../Page';
import { signingKeysQuery } from '../signingKeys/queries';
import {
  SigningKeysQuery,
  SigningKeysQueryVariables,
} from '../signingKeys/types/queries';
import {
  versionAddMutation,
  versionQuery,
  versionRemoveMutation,
  versionUpdateMutation,
} from './queries';
import {
  VersionAddMutation,
  VersionAddMutationVariables,
  VersionFragment,
  VersionQuery,
  VersionQueryVariables,
  VersionRemoveMutation,
  VersionRemoveMutationVariables,
  VersionUpdateMutation,
  VersionUpdateMutationVariables,
} from './types/queries';

type VersionState = PartialFragment<
  VersionFragment,
  'versionString' | 'qtyServers' | 'servers'
>;

const VersionEditPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<{ id: string }>();
  const isNew = params.id === 'new';

  const [error, setError] = useState('');

  const [state, setState] = useState<VersionState>({
    major: 0,
    minor: 0,
    patch: 0,
  });
  const { loading, data } = useQuery<VersionQuery, VersionQueryVariables>(
    versionQuery,
    {
      variables: { id: params.id },
      skip: isNew,
      onCompleted: (d) => {
        if (!d.version) return;
        setState(d.version);
      },
    }
  );

  const { data: skData, loading: skLoading } = useQuery<
    SigningKeysQuery,
    SigningKeysQueryVariables
  >(signingKeysQuery, {
    skip: !data?.version && !isNew,
    variables: {
      where: {
        or: [
          { expiration: { eq: null } },
          {
            id: {
              eq:
                data?.version?.signingKeyId ??
                '00000000-0000-0000-0000-000000000000',
            },
          },
        ],
      },
    },
  });

  const [update, { loading: updating }] = useMutation<
    VersionUpdateMutation,
    VersionUpdateMutationVariables
  >(versionUpdateMutation, {
    onError: (e) => setError(extractError(e)),
  });

  const [add, { loading: adding }] = useMutation<
    VersionAddMutation,
    VersionAddMutationVariables
  >(versionAddMutation, {
    onError: (e) => setError(extractError(e)),
  });

  const handleSave: MouseEventHandler<HTMLButtonElement> =
    useCallback(async () => {
      setError('');
      console.log(state);
      if (!state?.major) {
        setError('The major version number is required');
        return;
      }
      if (!state?.minor && state.minor !== 0) {
        setError('The minor version number is required');
        return;
      }
      if (!state?.patch && state.patch !== 0) {
        setError('The patch version number is required');
        return;
      }
      if (!state?.signingKeyId) {
        setError('The signing key is required');
        return;
      }
      if (isNew) {
        const result = await add({
          variables: {
            input: {
              major: state.major,
              minor: state.minor,
              patch: state.patch,
              comment: state.comment ?? '',
              signingKeyId: state.signingKeyId,
            },
          },
        });
        if (!result.errors) {
          nav('/admin/versions');
        }
      } else {
        const result = await update({
          variables: {
            input: {
              id: params.id,
              major: state.major,
              minor: state.minor,
              patch: state.patch,
              comment: state.comment ?? '',
              signingKeyId: state.signingKeyId,
            },
          },
        });
        if (!result.errors) {
          nav('/admin/versions');
        }
      }
    }, [add, isNew, state, nav, params.id, update]);

  const [remove, { loading: removing }] = useMutation<
    VersionRemoveMutation,
    VersionRemoveMutationVariables
  >(versionRemoveMutation);

  const isChanging = updating || adding || removing;
  return (
    <Page
      title="Version"
      backButton
      options={
        <>
          {!isNew && (
            <Tooltip title="Delete Version">
              <span>
                <IconButton
                  color="error"
                  disabled={
                    isChanging ||
                    data?.version?.qtyServers === undefined ||
                    data.version.qtyServers > 0
                  }
                  onClick={async () => {
                    await remove({
                      variables: { id: params.id },
                    });
                    nav('/admin/versions', { replace: true });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={2}>
          <Grid item>
            <EditPageCard error={error} title={data?.version?.versionString}>
              <TextField
                disabled={isChanging}
                value={state?.major || 0}
                type="number"
                onChange={(e) =>
                  setState({ ...state, major: parseFloat(e.target.value) })
                }
                label="Major"
                variant="outlined"
              />
              <TextField
                disabled={isChanging}
                value={state?.minor || 0}
                type="number"
                onChange={(e) =>
                  setState({ ...state, minor: parseFloat(e.target.value) })
                }
                label="Minor"
                variant="outlined"
              />
              <TextField
                disabled={isChanging}
                value={state?.patch || 0}
                type="number"
                onChange={(e) =>
                  setState({ ...state, patch: parseFloat(e.target.value) })
                }
                label="Patch"
                variant="outlined"
              />
              <TextField
                sx={{ width: 465 }}
                disabled={isChanging}
                multiline
                value={state?.comment || ''}
                label="Comment"
                variant="outlined"
                onChange={(e) =>
                  setState({ ...state, comment: e.target.value })
                }
              />
              <TextField
                disabled={isChanging || skLoading}
                value={state?.signingKeyId || ''}
                select
                onChange={(e) =>
                  setState({ ...state, signingKeyId: e.target.value })
                }
                label="Signing Key"
                variant="outlined"
              >
                {skData?.signingKeys ? (
                  skData.signingKeys.map((sk) => (
                    <MenuItem key={sk.id} value={sk.id}>
                      {sk.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem key="loading" disabled>
                    Loading
                  </MenuItem>
                )}
              </TextField>
              <Box>
                <LoadingButton
                  loading={isChanging}
                  startIcon={<Save />}
                  onClick={handleSave}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Box>
            </EditPageCard>
          </Grid>
          <Grid item xs={6}>
            <EditPageCard title="Servers">
              {data?.version?.servers === undefined ||
              data.version.servers.length === 0 ? (
                <em>None</em>
              ) : (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Owner</TableCell>
                      <TableCell>Server</TableCell>
                      <TableCell>Last Seen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.version.servers.map((s) => (
                      <TableRow key={s.id}>
                        <TableCell>
                          <NavLink to={`/admin/owners/${s.owner.id}`}>
                            {s.owner.name}
                          </NavLink>
                        </TableCell>
                        <TableCell>{s.name}</TableCell>
                        <TableCell>{dateTimeFormat(s.lastSeen)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </EditPageCard>
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default VersionEditPage;
