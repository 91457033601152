import { useMutation, useQuery } from '@apollo/client';
import { History, Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useCallback, useState } from 'react';
import EditPageCard from 'lib/components/EditPageCard';
import { useNavigate, useParams } from 'react-router-dom';
import { dateTimeFormat } from '../../../lib/formatters';
import Page from '../../Page';
import {
  signingKeyAddMutation,
  signingKeyExpireMutation,
  signingKeyQuery,
  signingKeyRemoveMutation,
  signingKeysQuery,
  signingKeyUpdateMutation,
} from './queries';
import {
  SigningKeyAddMutation,
  SigningKeyAddMutationVariables,
  SigningKeyExpireMutation,
  SigningKeyExpireMutationVariables,
  SigningKeyQuery,
  SigningKeyQueryVariables,
  SigningKeyRemoveMutation,
  SigningKeyRemoveMutationVariables,
  SigningKeyUpdateMutation,
  SigningKeyUpdateMutationVariables,
} from './types/queries';

const EditUserPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<'id'>();
  const isNew = params.id === 'new';

  const [expireConfirm, setExpireConfirm] = useState(false);
  const [removeConfirm, setRemoveConfirm] = useState(false);
  const [name, setName] = useState<string>();
  const [error, setError] = useState('');

  const { data, loading } = useQuery<SigningKeyQuery, SigningKeyQueryVariables>(
    signingKeyQuery,
    {
      variables: { id: params.id },
      skip: isNew,
      fetchPolicy: 'cache-and-network',
      onCompleted: (d) => setName(d.signingKey?.name),
    }
  );
  const [expireKey, { loading: expiring }] = useMutation<
    SigningKeyExpireMutation,
    SigningKeyExpireMutationVariables
  >(signingKeyExpireMutation, {
    refetchQueries: [{ query: signingKeysQuery }],
    awaitRefetchQueries: false,
  });

  const [add, { loading: adding }] = useMutation<
    SigningKeyAddMutation,
    SigningKeyAddMutationVariables
  >(signingKeyAddMutation, {
    refetchQueries: [{ query: signingKeysQuery }],
    awaitRefetchQueries: true,
  });
  const [update, { loading: updating }] = useMutation<
    SigningKeyUpdateMutation,
    SigningKeyUpdateMutationVariables
  >(signingKeyUpdateMutation, {
    refetchQueries: [{ query: signingKeysQuery }],
    awaitRefetchQueries: false,
  });
  const [remove, { loading: removing }] = useMutation<
    SigningKeyRemoveMutation,
    SigningKeyRemoveMutationVariables
  >(signingKeyRemoveMutation, {
    refetchQueries: [{ query: signingKeysQuery }],
    awaitRefetchQueries: true,
  });

  const handleClose = useCallback(() => {
    setRemoveConfirm(false);
    setExpireConfirm(false);
  }, []);

  const isChanging = updating || adding || expiring || removing;
  return (
    <Page backButton title="Signing Key">
      {loading ? (
        <LinearProgress />
      ) : (
        <EditPageCard
          title={isNew ? 'New Signing Key' : data?.signingKey?.name}
          fullWidth
          error={error}
        >
          <Box>
            <TextField
              required
              disabled={isChanging}
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              label="Name"
              sx={{ minWidth: 300 }}
            />
            <LoadingButton
              sx={{ mt: 1, ml: 2 }}
              loading={adding || updating}
              disabled={isChanging || !name}
              startIcon={<Save />}
              variant="contained"
              onClick={async () => {
                if (!name) {
                  setError('The name field is required');
                  return;
                }
                setError('');
                if (isNew) {
                  const result = await add({ variables: { name } });
                  if (result.data?.signingKeyAdd.id) {
                    nav(`/admin/signing_keys/${result.data.signingKeyAdd.id}`, {
                      replace: true,
                    });
                  }
                } else {
                  const result = await update({
                    variables: { input: { name, id: params.id } },
                  });
                  if (result.data?.signingKeyUpdate.id) {
                    nav('/admin/signing_keys');
                  }
                }
              }}
            >
              Save
            </LoadingButton>
          </Box>
          {!isNew && (
            <>
              <Divider sx={{ mr: -2, ml: -2, mb: 2 }} />
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Typography variant="subtitle2">
                    Created on{' '}
                    {new Date(data?.signingKey?.created).toLocaleDateString()}
                  </Typography>
                  {data?.signingKey?.expiration && (
                    <Typography variant="subtitle2">
                      Expired on{' '}
                      {new Date(
                        data.signingKey.expiration
                      ).toLocaleDateString()}{' '}
                      at{' '}
                      {new Date(
                        data.signingKey.expiration
                      ).toLocaleTimeString()}
                      .
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="error"
                    disabled={
                      isChanging ||
                      !!data?.signingKey?.qtyLicenseKeys ||
                      !!data?.signingKey?.qtyVersions
                    }
                    startIcon={<DeleteIcon />}
                    onClick={() => setRemoveConfirm(true)}
                  >
                    Delete Key
                  </Button>
                  {data?.signingKey?.expiration === null && (
                    <Button
                      sx={{ ml: 2 }}
                      disabled={isChanging}
                      startIcon={<History />}
                      color="error"
                      onClick={() => setExpireConfirm(true)}
                      variant="outlined"
                    >
                      Expire Key
                    </Button>
                  )}
                </Box>
              </Box>
              <Typography variant="h6" style={{ marginTop: '10px' }}>
                Public Key
              </Typography>
              <pre>
                <Typography
                  style={{
                    backgroundColor: '#ededed',
                    padding: '10px',
                    wordBreak: 'break-all',
                    fontFamily: 'monospace',
                    fontSize: 14,
                    marginTop: 10,
                    borderRadius: 10,
                  }}
                >
                  {data?.signingKey?.publicKey}
                </Typography>
              </pre>

              <Typography variant="h6" style={{ marginTop: '15px' }}>
                Versions
              </Typography>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Version</TableCell>
                      <TableCell>Qty Servers</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.signingKey?.versions === undefined ||
                    data.signingKey.versions.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4}>No Versions</TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {data.signingKey.versions.map((v) => (
                          <TableRow>
                            <TableCell>
                              <a
                                href={`/admin/versions/${v.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  nav(`/admin/versions/${v.id}`);
                                }}
                              >
                                {v.versionString}
                              </a>
                            </TableCell>
                            <TableCell>{v.qtyServers}</TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography
                variant="h6"
                style={{
                  marginTop: '15px',
                  marginBottom: '15px',
                }}
              >
                Recent License Keys
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Issued</TableCell>
                      <TableCell>Expiration</TableCell>
                      <TableCell>Owner</TableCell>
                      <TableCell>Server</TableCell>
                      <TableCell>Version</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.signingKey?.recentLicenseKeys === undefined ||
                    data.signingKey.recentLicenseKeys.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                          No Recent Keys
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {data.signingKey.recentLicenseKeys.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell>{dateTimeFormat(row.issued)}</TableCell>
                            <TableCell>
                              {dateTimeFormat(row.expiration)}
                            </TableCell>
                            <TableCell>
                              <a
                                href={`/admin/owners/${row.server.owner.id}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  nav(`/admin/owners/${row.server.owner.id}`);
                                }}
                              >
                                {row.server.owner.name}
                              </a>
                            </TableCell>
                            <TableCell>{row.server.name}</TableCell>
                            <TableCell>
                              {row.server.version?.versionString}
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </EditPageCard>
      )}
      {!isNew && (
        <Dialog open={expireConfirm || removeConfirm} onClose={handleClose}>
          <DialogTitle>
            {removeConfirm ? 'Remove' : 'Expire'} Signing Key
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to {removeConfirm ? 'remove' : 'expire'}{' '}
              this signing key? All existing customer license keys must be
              updated!!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <LoadingButton
              autoFocus
              color="error"
              onClick={async () => {
                if (removeConfirm) {
                  await remove({ variables: { id: params.id } });
                  nav('/admin/signing_keys');
                } else {
                  await expireKey({ variables: { id: params.id } });
                }
                handleClose();
              }}
              loading={isChanging}
              startIcon={removeConfirm ? <DeleteIcon /> : <Save />}
              variant="outlined"
            >
              {removeConfirm ? 'Remove' : 'Expire'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </Page>
  );
};

export default EditUserPage;
