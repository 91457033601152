/*import 'bootstrap/dist/css/bootstrap.css';*/
import React from 'react';
import { createRoot } from 'react-dom/client'
import { App } from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement!);

root.render(<App baseUrl={baseUrl!} />);


