import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { authEvents } from '../UserContext/AuthEvents';
import { StrictTypedTypePolicies } from './queryTypePolicies';
import possibleTypes from './possibleTypes.json';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (
        extensions &&
        'code' in extensions &&
        extensions.code === 'AUTH_NOT_AUTHENTICATED'
      ) {
        authEvents.emit('notAuthenticated', extensions.code);
      }
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
});

const wsLinkNew = new GraphQLWsLink(
  createClient({
    url:
      (window.location.protocol === 'https:' ? 'wss://' : 'ws://') +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      '/graphql/',
    // Must be lazy so that it doesn't establish a connection when not logged in. If it does, the connection will be
    // in an "unauthorized" state and websockets won't work.
    lazy: true,
  })
);

const httpLink = new HttpLink({
  uri: '/graphql/',
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLinkNew,
  httpLink
);

const typePolicies: StrictTypedTypePolicies = {};

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies,
});

export const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  connectToDevTools: true,
  cache,
  credentials: 'include',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});
