import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import OwnerServerSelector from 'lib/components/OwnerServerSelector';
import { assignMachineMutation } from './queries';
import {
  ConnectedClientFragment,
  ServerAssignMutation,
  ServerAssignMutationVariables,
} from './types/queries';

interface Props {
  connectedClient?: ConnectedClientFragment;
  onClose: (changed?: boolean) => void;
}

const AssignServerDialog: FC<Props> = ({ connectedClient, onClose }) => {
  const isOpen = !!connectedClient;

  const [ownerId, setOwnerId] = useState<string>('');
  const [serverId, setServerId] = useState<string>('');

  useEffect(() => {
    if (connectedClient?.server?.owner) {
      setOwnerId(connectedClient.server.owner.id);
    }
    if (connectedClient?.server) {
      setServerId(connectedClient.server.id);
    }
  }, [connectedClient]);

  const [assign, { loading: assigning }] = useMutation<
    ServerAssignMutation,
    ServerAssignMutationVariables
  >(assignMachineMutation);

  const doAssign = useCallback(async () => {
    if (!serverId) {
      return;
    }
    await assign({
      variables: {
        serverId: serverId,
        machineId: connectedClient!.machineId,
      },
    });
    onClose(true);
  }, [assign, connectedClient, onClose, serverId]);

  const isAssigned = !!connectedClient?.server;

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      fullWidth
      sx={{ '.MuiDialog-paperFullWidth': { maxWidth: 1200 } }}
    >
      <DialogTitle>{isAssigned ? 'Reassign' : 'Assign'} Server</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={6} lg={8} sx={{ overflow: 'hidden' }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>IP</TableCell>
                  <TableCell>{connectedClient?.ipAddress}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Machine ID</TableCell>
                  <TableCell>
                    {connectedClient?.machineId.slice(0, 10)}...
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>{connectedClient?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Companies</TableCell>
                  <TableCell>
                    {connectedClient?.companies && (
                      <Box sx={{ maxHeight: 350, overflow: 'auto' }}>
                        <ul style={{ marginTop: 0, marginBottom: 0 }}>
                          {connectedClient.companies.map((company) => (
                            <li key={company.localId}>{company.name}</li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            <OwnerServerSelector
              skip={!isOpen}
              ownerId={ownerId}
              setOwnerId={setOwnerId}
              serverId={serverId}
              setServerId={setServerId}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <LoadingButton
          loading={assigning}
          variant="contained"
          disabled={!serverId || !ownerId}
          onClick={doAssign}
        >
          {isAssigned ? 'Reassign' : 'Assign'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignServerDialog;
