import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import GridWrapper from 'lib/GridWrapper';
import Page from '../../Page';
import { usersQuery } from './queries';
import { UserFragment, UsersQuery } from './types/queries';

const UsersPage: FC = () => {
  const { data, loading } = useQuery<UsersQuery>(usersQuery);

  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'User',
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      disableColumnMenu: true,
    },
    {
      field: 'isAdmin',
      headerName: 'Admin',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Checkbox disabled checked={params.value} />
      ),
    },
    {
      field: 'isTech',
      headerName: 'Tech',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Checkbox disabled checked={params.value} />
      ),
    },
    {
      field: 'salesOffices',
      headerName: 'Sales Offices',
      width: 250,
      disableColumnMenu: true,
      valueGetter: (params) =>
        (params.value as UserFragment['salesOffices'])
          .map((o) => o.name)
          .join(', '),
    },
    {
      field: 'twoFactorEnabled',
      headerName: '2FA Enabled',
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Checkbox disabled checked={params.value} />
      ),
    },
  ];

  const nav = useNavigate();

  return (
    <Page
      options={
        <Tooltip title="Add user">
          <IconButton
            style={{ color: 'white' }}
            onClick={() => nav('/admin/users/new')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
      title="Users"
    >
      <GridWrapper
        columns={columns}
        rows={data?.users ?? []}
        loading={loading}
        basePath={'/admin/users/'}
      />
    </Page>
  );
};

export default UsersPage;
