import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import GridWrapper from 'lib/GridWrapper';
import Page from '../../Page';
import { salesOfficesQuery } from './queries';
import { SalesOfficesQuery, SalesOfficesQueryVariables } from './types/queries';

const SalesOffices: FC = () => {
  const nav = useNavigate();
  const { data, loading } = useQuery<
    SalesOfficesQuery,
    SalesOfficesQueryVariables
  >(salesOfficesQuery, { fetchPolicy: 'cache-and-network' });

  return (
    <Page
      title="Sales Offices"
      options={
        <>
          <Tooltip title="Add Sales Office">
            <span>
              <IconButton
                sx={{ color: 'white' }}
                onClick={() => nav('/admin/sales_offices/new')}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      }
    >
      <GridWrapper
        columns={[
          { field: 'name', headerName: 'Name', width: 300 },
          { field: 'ownersQty', headerName: 'Owners', width: 150 },
          { field: 'usersQty', headerName: 'Users', width: 150 },
        ]}
        rows={data?.salesOffices ?? []}
        loading={loading}
        basePath={'/admin/sales_offices/'}
      />
    </Page>
  );
};

export default SalesOffices;
