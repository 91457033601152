export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  UUID: any;
};

export type ApplicationUserFilterInput = {
  accessFailedCount?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<ApplicationUserFilterInput>>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  emailConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lockoutEnabled?: InputMaybe<BooleanOperationFilterInput>;
  lockoutEnd?: InputMaybe<DateTimeOperationFilterInput>;
  normalizedEmail?: InputMaybe<StringOperationFilterInput>;
  normalizedUserName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationUserFilterInput>>;
  passwordHash?: InputMaybe<StringOperationFilterInput>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  phoneNumberConfirmed?: InputMaybe<BooleanOperationFilterInput>;
  securityStamp?: InputMaybe<StringOperationFilterInput>;
  twoFactorEnabled?: InputMaybe<BooleanOperationFilterInput>;
  userName?: InputMaybe<StringOperationFilterInput>;
  yubiKeys?: InputMaybe<ListFilterInputTypeOfUserYubiKeyFilterInput>;
};

export type ApplicationUserSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  concurrencyStamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  lockoutEnd?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  securityStamp?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']>;
  gt?: InputMaybe<Scalars['Byte']>;
  gte?: InputMaybe<Scalars['Byte']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']>>>;
  lt?: InputMaybe<Scalars['Byte']>;
  lte?: InputMaybe<Scalars['Byte']>;
  neq?: InputMaybe<Scalars['Byte']>;
  ngt?: InputMaybe<Scalars['Byte']>;
  ngte?: InputMaybe<Scalars['Byte']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']>>>;
  nlt?: InputMaybe<Scalars['Byte']>;
  nlte?: InputMaybe<Scalars['Byte']>;
};

export type ClientConnectionEvent = {
  __typename?: 'ClientConnectionEvent';
  client: ConnectedClient;
  eventType: ClientConnectionEventType;
};

export enum ClientConnectionEventType {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED'
}

export type ClientConnectionsInfo = {
  __typename?: 'ClientConnectionsInfo';
  event?: Maybe<ClientConnectionEvent>;
  qtyConnections: Scalars['Int'];
};

export type Company = {
  __typename?: 'Company';
  allocatedUsers: Scalars['Int'];
  companyFeatures: Array<CompanyFeature>;
  id: Scalars['UUID'];
  localCompanyId?: Maybe<Scalars['UUID']>;
  localCompanyName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  serverId: Scalars['UUID'];
};

export type CompanyFeature = {
  __typename?: 'CompanyFeature';
  companyId: Scalars['UUID'];
  expiration?: Maybe<Scalars['Date']>;
  feature: Feature;
  featureId: Scalars['UUID'];
  id: Scalars['UUID'];
};

export type CompanyFeatureFilterInput = {
  and?: InputMaybe<Array<CompanyFeatureFilterInput>>;
  company?: InputMaybe<CompanyFilterInput>;
  companyId?: InputMaybe<UuidOperationFilterInput>;
  expiration?: InputMaybe<DateOperationFilterInput>;
  feature?: InputMaybe<FeatureFilterInput>;
  featureId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<CompanyFeatureFilterInput>>;
};

export type CompanyFilterInput = {
  allocatedUsers?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<CompanyFilterInput>>;
  companyFeatures?: InputMaybe<ListFilterInputTypeOfCompanyFeatureFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  localCompanyId?: InputMaybe<UuidOperationFilterInput>;
  localCompanyName?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CompanyFilterInput>>;
  server?: InputMaybe<ServerFilterInput>;
  serverId?: InputMaybe<UuidOperationFilterInput>;
};

export type CompanyInfoInput = {
  /** The ID of the company in Server Core */
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type CompanyInput = {
  allocatedUsers: Scalars['Int'];
  features: Array<FeatureInput>;
  /** Leave null when adding a new company */
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

export type CompanyLocalNameInput = {
  id?: InputMaybe<Scalars['UUID']>;
  localId?: InputMaybe<Scalars['UUID']>;
  localName?: InputMaybe<Scalars['String']>;
};

export type ConnectedClient = {
  __typename?: 'ConnectedClient';
  companies?: Maybe<Array<ConnectedClientCompany>>;
  connectionTime: Scalars['DateTime'];
  id: Scalars['UUID'];
  ipAddress?: Maybe<Scalars['String']>;
  machineId: Scalars['String'];
  name: Scalars['String'];
  server?: Maybe<Server>;
  version: Scalars['String'];
};

export type ConnectedClientCompany = {
  __typename?: 'ConnectedClientCompany';
  localId: Scalars['UUID'];
  name: Scalars['String'];
};

export type DateOperationFilterInput = {
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  neq?: InputMaybe<Scalars['Date']>;
  ngt?: InputMaybe<Scalars['Date']>;
  ngte?: InputMaybe<Scalars['Date']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  nlt?: InputMaybe<Scalars['Date']>;
  nlte?: InputMaybe<Scalars['Date']>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type Feature = {
  __typename?: 'Feature';
  companiesQty: Scalars['Int'];
  firstVersion?: Maybe<Version>;
  firstVersionId?: Maybe<Scalars['UUID']>;
  hasExpiration: Scalars['Boolean'];
  id: Scalars['UUID'];
  lastVersion?: Maybe<Version>;
  lastVersionId?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  packages: Array<Package>;
  packagesQty: Scalars['Int'];
};

export type FeatureAddInput = {
  firstVersionId?: InputMaybe<Scalars['UUID']>;
  hasExpiration: Scalars['Boolean'];
  lastVersionId?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

export type FeatureFilterInput = {
  and?: InputMaybe<Array<FeatureFilterInput>>;
  firstVersion?: InputMaybe<VersionFilterInput>;
  firstVersionId?: InputMaybe<UuidOperationFilterInput>;
  hasExpiration?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastVersion?: InputMaybe<VersionFilterInput>;
  lastVersionId?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FeatureFilterInput>>;
  packageFeatures?: InputMaybe<ListFilterInputTypeOfPackageFeatureFilterInput>;
  packages?: InputMaybe<ListFilterInputTypeOfPackageFilterInput>;
};

export type FeatureInput = {
  expiration?: InputMaybe<Scalars['Date']>;
  featureId: Scalars['UUID'];
};

export type FeatureSortInput = {
  firstVersion?: InputMaybe<VersionSortInput>;
  firstVersionId?: InputMaybe<SortEnumType>;
  hasExpiration?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastVersion?: InputMaybe<VersionSortInput>;
  lastVersionId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type FeatureUpdateInput = {
  firstVersionId?: InputMaybe<Scalars['UUID']>;
  hasExpiration: Scalars['Boolean'];
  id: Scalars['UUID'];
  lastVersionId?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type KeyReadyEvent = {
  __typename?: 'KeyReadyEvent';
  isReady: Scalars['Boolean'];
  secret: Scalars['String'];
};

export type LicenseKeyGenerateInput = {
  machineId: Scalars['String'];
  serverId: Scalars['UUID'];
  validTimeSpan?: InputMaybe<Scalars['TimeSpan']>;
  version: Scalars['String'];
};

export type ListByteOperationFilterInput = {
  all?: InputMaybe<ByteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ByteOperationFilterInput>;
  some?: InputMaybe<ByteOperationFilterInput>;
};

export type ListFilterInputTypeOfCompanyFeatureFilterInput = {
  all?: InputMaybe<CompanyFeatureFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CompanyFeatureFilterInput>;
  some?: InputMaybe<CompanyFeatureFilterInput>;
};

export type ListFilterInputTypeOfCompanyFilterInput = {
  all?: InputMaybe<CompanyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<CompanyFilterInput>;
  some?: InputMaybe<CompanyFilterInput>;
};

export type ListFilterInputTypeOfFeatureFilterInput = {
  all?: InputMaybe<FeatureFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<FeatureFilterInput>;
  some?: InputMaybe<FeatureFilterInput>;
};

export type ListFilterInputTypeOfOwnerFilterInput = {
  all?: InputMaybe<OwnerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<OwnerFilterInput>;
  some?: InputMaybe<OwnerFilterInput>;
};

export type ListFilterInputTypeOfPackageFeatureFilterInput = {
  all?: InputMaybe<PackageFeatureFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PackageFeatureFilterInput>;
  some?: InputMaybe<PackageFeatureFilterInput>;
};

export type ListFilterInputTypeOfPackageFilterInput = {
  all?: InputMaybe<PackageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<PackageFilterInput>;
  some?: InputMaybe<PackageFilterInput>;
};

export type ListFilterInputTypeOfServerFilterInput = {
  all?: InputMaybe<ServerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ServerFilterInput>;
  some?: InputMaybe<ServerFilterInput>;
};

export type ListFilterInputTypeOfServerPackageFilterInput = {
  all?: InputMaybe<ServerPackageFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ServerPackageFilterInput>;
  some?: InputMaybe<ServerPackageFilterInput>;
};

export type ListFilterInputTypeOfTokenFilterInput = {
  all?: InputMaybe<TokenFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<TokenFilterInput>;
  some?: InputMaybe<TokenFilterInput>;
};

export type ListFilterInputTypeOfUserYubiKeyFilterInput = {
  all?: InputMaybe<UserYubiKeyFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<UserYubiKeyFilterInput>;
  some?: InputMaybe<UserYubiKeyFilterInput>;
};

export type ListFilterInputTypeOfVersionFilterInput = {
  all?: InputMaybe<VersionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<VersionFilterInput>;
  some?: InputMaybe<VersionFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  companyLocalNameUpdate: Scalars['Boolean'];
  featureAdd: Feature;
  featureRemove: Scalars['Boolean'];
  featureUpdate: Feature;
  forgotPassword: Scalars['Boolean'];
  licenseKeyGenerate: Array<Scalars['String']>;
  machineKeysGenerate: Array<Scalars['String']>;
  ownerAdd: Owner;
  ownerRemove: Scalars['Boolean'];
  ownerUpdate: Owner;
  packageAdd: Package;
  packageRemove: Scalars['Boolean'];
  packageUpdate: Package;
  profileUpdate: User;
  salesOfficeAdd: SalesOffice;
  salesOfficeRemove: Scalars['Boolean'];
  salesOfficeUpdate: SalesOffice;
  serverAssignMachine: Server;
  signIn: SignInResponse;
  signInVerifyYubiKeyOtp: SignInResponse;
  signOut: Scalars['Boolean'];
  signingKeyAdd: SigningKey;
  signingKeyExpire: SigningKey;
  signingKeyRemove: Scalars['Boolean'];
  signingKeyUpdate: SigningKey;
  userAdd: User;
  userRemove: Scalars['Boolean'];
  userUpdate: User;
  userYubiKeyAdd: User;
  userYubiKeyDelete: Scalars['Boolean'];
  userYubiKeyUpdate: UserYubiKey;
  versionAdd: Version;
  versionRemove: Scalars['Boolean'];
  versionUpdate: Version;
};


export type MutationCompanyLocalNameUpdateArgs = {
  input: Array<CompanyLocalNameInput>;
};


export type MutationFeatureAddArgs = {
  input: FeatureAddInput;
};


export type MutationFeatureRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationFeatureUpdateArgs = {
  input: FeatureUpdateInput;
};


export type MutationForgotPasswordArgs = {
  usernameOrEmail: Scalars['String'];
};


export type MutationLicenseKeyGenerateArgs = {
  input: LicenseKeyGenerateInput;
};


export type MutationMachineKeysGenerateArgs = {
  serverVersion: Scalars['String'];
};


export type MutationOwnerAddArgs = {
  input: OwnerAddInput;
};


export type MutationOwnerRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationOwnerUpdateArgs = {
  input: OwnerUpdateInput;
};


export type MutationPackageAddArgs = {
  input: PackageAddInput;
};


export type MutationPackageRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationPackageUpdateArgs = {
  input: PackageUpdateInput;
};


export type MutationProfileUpdateArgs = {
  input: ProfileUpdateInput;
};


export type MutationSalesOfficeAddArgs = {
  input: SalesOfficeAddInput;
};


export type MutationSalesOfficeRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationSalesOfficeUpdateArgs = {
  input: SalesOfficeUpdateInput;
};


export type MutationServerAssignMachineArgs = {
  input: ServerAssignMachineInput;
};


export type MutationSignInArgs = {
  input: SignInInput;
};


export type MutationSignInVerifyYubiKeyOtpArgs = {
  input: SignInYubiKeyOtpInput;
};


export type MutationSigningKeyAddArgs = {
  name: Scalars['String'];
};


export type MutationSigningKeyExpireArgs = {
  id: Scalars['UUID'];
};


export type MutationSigningKeyRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationSigningKeyUpdateArgs = {
  input: SigningKeyUpdateInput;
};


export type MutationUserAddArgs = {
  input: UserAddInput;
};


export type MutationUserRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};


export type MutationUserYubiKeyAddArgs = {
  input: YubiKeyAddInput;
};


export type MutationUserYubiKeyDeleteArgs = {
  input: YubiKeyDeleteInput;
};


export type MutationUserYubiKeyUpdateArgs = {
  input: YubiKeyUpdateInput;
};


export type MutationVersionAddArgs = {
  input: VersionAddInput;
};


export type MutationVersionRemoveArgs = {
  id: Scalars['UUID'];
};


export type MutationVersionUpdateArgs = {
  input: VersionUpdateInput;
};

export type Owner = {
  __typename?: 'Owner';
  companiesQty: Scalars['Int'];
  email: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  salesOffice: SalesOffice;
  salesOfficeId: Scalars['UUID'];
  servers: Array<Server>;
  serversQty: Scalars['Int'];
  usersQty: Scalars['Int'];
};

export type OwnerAddInput = {
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  salesOfficeId: Scalars['UUID'];
  servers: Array<ServerInput>;
};

export type OwnerFilterInput = {
  and?: InputMaybe<Array<OwnerFilterInput>>;
  email?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OwnerFilterInput>>;
  salesOffice?: InputMaybe<SalesOfficeFilterInput>;
  salesOfficeId?: InputMaybe<UuidOperationFilterInput>;
  servers?: InputMaybe<ListFilterInputTypeOfServerFilterInput>;
};

export type OwnerSortInput = {
  email?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  salesOffice?: InputMaybe<SalesOfficeSortInput>;
  salesOfficeId?: InputMaybe<SortEnumType>;
};

export type OwnerUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  salesOfficeId: Scalars['UUID'];
  servers: Array<ServerInput>;
};

export type Package = {
  __typename?: 'Package';
  features: Array<Feature>;
  featuresQty: Scalars['Int'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  serversQty: Scalars['Int'];
};

export type PackageAddInput = {
  featureIds: Array<Scalars['UUID']>;
  name: Scalars['String'];
};

export type PackageFeature = {
  __typename?: 'PackageFeature';
  feature: Feature;
  featureId: Scalars['UUID'];
  package: Package;
  packageId: Scalars['UUID'];
};

export type PackageFeatureFilterInput = {
  and?: InputMaybe<Array<PackageFeatureFilterInput>>;
  feature?: InputMaybe<FeatureFilterInput>;
  featureId?: InputMaybe<UuidOperationFilterInput>;
  or?: InputMaybe<Array<PackageFeatureFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  packageId?: InputMaybe<UuidOperationFilterInput>;
};

export type PackageFilterInput = {
  and?: InputMaybe<Array<PackageFilterInput>>;
  features?: InputMaybe<ListFilterInputTypeOfFeatureFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PackageFilterInput>>;
  packageFeatures?: InputMaybe<ListFilterInputTypeOfPackageFeatureFilterInput>;
};

export type PackageSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type PackageUpdateInput = {
  featureIds: Array<Scalars['UUID']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type ProfileUpdateInput = {
  email: Scalars['String'];
  existingPassword?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  newPassword?: InputMaybe<Scalars['String']>;
  requireTwoFactor?: InputMaybe<Scalars['Boolean']>;
};

export type Query = {
  __typename?: 'Query';
  connectedClients: Array<ConnectedClient>;
  feature?: Maybe<Feature>;
  features: Array<Feature>;
  licenseKeysRecent: Array<LicenseKey>;
  owner?: Maybe<Owner>;
  owners: Array<Owner>;
  package?: Maybe<Package>;
  packages: Array<Package>;
  salesOffice?: Maybe<SalesOffice>;
  salesOffices: Array<SalesOffice>;
  servers: Array<Server>;
  signingKey?: Maybe<SigningKey>;
  signingKeys: Array<SigningKey>;
  user?: Maybe<User>;
  users: Array<User>;
  version?: Maybe<Version>;
  versions: Array<Version>;
  whoAmI?: Maybe<User>;
};


export type QueryFeatureArgs = {
  id: Scalars['UUID'];
};


export type QueryFeaturesArgs = {
  order?: InputMaybe<Array<FeatureSortInput>>;
  where?: InputMaybe<FeatureFilterInput>;
};


export type QueryLicenseKeysRecentArgs = {
  serverId: Scalars['UUID'];
};


export type QueryOwnerArgs = {
  id: Scalars['UUID'];
};


export type QueryOwnersArgs = {
  order?: InputMaybe<Array<OwnerSortInput>>;
  where?: InputMaybe<OwnerFilterInput>;
};


export type QueryPackageArgs = {
  id: Scalars['UUID'];
};


export type QueryPackagesArgs = {
  order?: InputMaybe<Array<PackageSortInput>>;
  where?: InputMaybe<PackageFilterInput>;
};


export type QuerySalesOfficeArgs = {
  id: Scalars['UUID'];
};


export type QuerySalesOfficesArgs = {
  order?: InputMaybe<Array<SalesOfficeSortInput>>;
};


export type QueryServersArgs = {
  where?: InputMaybe<ServerFilterInput>;
};


export type QuerySigningKeyArgs = {
  id: Scalars['UUID'];
};


export type QuerySigningKeysArgs = {
  order?: InputMaybe<Array<SigningKeySortInput>>;
  where?: InputMaybe<SigningKeyFilterInput>;
};


export type QueryUserArgs = {
  id: Scalars['UUID'];
};


export type QueryUsersArgs = {
  order?: InputMaybe<Array<ApplicationUserSortInput>>;
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type QueryVersionArgs = {
  id: Scalars['UUID'];
};


export type QueryVersionsArgs = {
  order?: InputMaybe<Array<VersionSortInput>>;
  where?: InputMaybe<VersionFilterInput>;
};

export type SalesOffice = {
  __typename?: 'SalesOffice';
  id: Scalars['UUID'];
  name: Scalars['String'];
  owners: Array<Owner>;
  ownersQty: Scalars['Int'];
  usersQty: Scalars['Int'];
};

export type SalesOfficeAddInput = {
  name: Scalars['String'];
};

export type SalesOfficeFilterInput = {
  and?: InputMaybe<Array<SalesOfficeFilterInput>>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SalesOfficeFilterInput>>;
  owners?: InputMaybe<ListFilterInputTypeOfOwnerFilterInput>;
};

export type SalesOfficeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type SalesOfficeUpdateInput = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export type Server = {
  __typename?: 'Server';
  backupFor?: Maybe<Server>;
  backupForId?: Maybe<Scalars['UUID']>;
  companies?: Maybe<Array<Company>>;
  id: Scalars['UUID'];
  lastSeen?: Maybe<Scalars['DateTime']>;
  licenseKeysQty: Scalars['Int'];
  localName?: Maybe<Scalars['String']>;
  machineId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner: Owner;
  ownerId: Scalars['UUID'];
  packages: Array<Package>;
  recentLicenseKeys: Array<LicenseKey>;
  version?: Maybe<Version>;
  versionId?: Maybe<Scalars['UUID']>;
};

export type ServerAssignMachineInput = {
  machineId: Scalars['String'];
  serverId: Scalars['UUID'];
};

export type ServerFilterInput = {
  and?: InputMaybe<Array<ServerFilterInput>>;
  backupFor?: InputMaybe<ServerFilterInput>;
  backupForId?: InputMaybe<UuidOperationFilterInput>;
  backupServers?: InputMaybe<ListFilterInputTypeOfServerFilterInput>;
  companies?: InputMaybe<ListFilterInputTypeOfCompanyFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  lastSeen?: InputMaybe<DateTimeOperationFilterInput>;
  localName?: InputMaybe<StringOperationFilterInput>;
  machineId?: InputMaybe<StringOperationFilterInput>;
  machineSecret?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ServerFilterInput>>;
  owner?: InputMaybe<OwnerFilterInput>;
  ownerId?: InputMaybe<UuidOperationFilterInput>;
  packages?: InputMaybe<ListFilterInputTypeOfPackageFilterInput>;
  serverPackages?: InputMaybe<ListFilterInputTypeOfServerPackageFilterInput>;
  tokens?: InputMaybe<ListFilterInputTypeOfTokenFilterInput>;
  version?: InputMaybe<VersionFilterInput>;
  versionId?: InputMaybe<UuidOperationFilterInput>;
};

export type ServerInfoInput = {
  companies?: InputMaybe<Array<CompanyInfoInput>>;
  machineId: Scalars['String'];
  name: Scalars['String'];
  version: Scalars['String'];
};

export type ServerInput = {
  /** The server that this server will back up. */
  backupForId?: InputMaybe<Scalars['UUID']>;
  companies?: InputMaybe<Array<CompanyInput>>;
  id?: InputMaybe<Scalars['UUID']>;
  name: Scalars['String'];
  packageIds: Array<Scalars['UUID']>;
};

export type ServerPackage = {
  __typename?: 'ServerPackage';
  package: Package;
  packageId: Scalars['UUID'];
  server: Server;
  serverId: Scalars['UUID'];
};

export type ServerPackageFilterInput = {
  and?: InputMaybe<Array<ServerPackageFilterInput>>;
  or?: InputMaybe<Array<ServerPackageFilterInput>>;
  package?: InputMaybe<PackageFilterInput>;
  packageId?: InputMaybe<UuidOperationFilterInput>;
  server?: InputMaybe<ServerFilterInput>;
  serverId?: InputMaybe<UuidOperationFilterInput>;
};

export type SignInInput = {
  password: Scalars['String'];
  rememberMe: Scalars['Boolean'];
  usernameOrEmail: Scalars['String'];
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  requiresMfa: Scalars['Boolean'];
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type SignInYubiKeyOtpInput = {
  otp: Scalars['String'];
  /** If 2-factor authentication should be required next time this user signs in on this device. */
  rememberClient: Scalars['Boolean'];
  rememberMe: Scalars['Boolean'];
};

export type SigningKey = {
  __typename?: 'SigningKey';
  algorithm: Scalars['String'];
  created: Scalars['DateTime'];
  expiration?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  publicKey: Scalars['String'];
  qtyLicenseKeys: Scalars['Int'];
  qtyVersions: Scalars['Int'];
  recentLicenseKeys: Array<LicenseKey>;
  versions: Array<Version>;
};

export type SigningKeyFilterInput = {
  algorithm?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<SigningKeyFilterInput>>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  expiration?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SigningKeyFilterInput>>;
  privateKey?: InputMaybe<ListByteOperationFilterInput>;
  publicKey?: InputMaybe<ListByteOperationFilterInput>;
  tokens?: InputMaybe<ListFilterInputTypeOfTokenFilterInput>;
  versions?: InputMaybe<ListFilterInputTypeOfVersionFilterInput>;
};

export type SigningKeySortInput = {
  algorithm?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  expiration?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type SigningKeyUpdateInput = {
  id: Scalars['UUID'];
  name: Scalars['String'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  clientConnections: ClientConnectionsInfo;
  keyRequest: KeyReadyEvent;
};


export type SubscriptionKeyRequestArgs = {
  serverInfo: ServerInfoInput;
};

export type TokenFilterInput = {
  and?: InputMaybe<Array<TokenFilterInput>>;
  contents?: InputMaybe<StringOperationFilterInput>;
  expiration?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  issued?: InputMaybe<DateTimeOperationFilterInput>;
  or?: InputMaybe<Array<TokenFilterInput>>;
  server?: InputMaybe<ServerFilterInput>;
  serverId?: InputMaybe<UuidOperationFilterInput>;
  signingKey?: InputMaybe<SigningKeyFilterInput>;
  signingKeyId?: InputMaybe<UuidOperationFilterInput>;
  version?: InputMaybe<VersionFilterInput>;
  versionId?: InputMaybe<UuidOperationFilterInput>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['UUID'];
  isAdmin: Scalars['Boolean'];
  isTech: Scalars['Boolean'];
  salesOffices: Array<SalesOffice>;
  twoFactorEnabled: Scalars['Boolean'];
  userName: Scalars['String'];
  yubiKeys: Array<UserYubiKey>;
};

export type UserAddInput = {
  email: Scalars['String'];
  isAdmin: Scalars['Boolean'];
  isTech: Scalars['Boolean'];
  name: Scalars['String'];
  password: Scalars['String'];
  salesOfficeIds: Array<Scalars['UUID']>;
};

export type UserUpdateInput = {
  email: Scalars['String'];
  id: Scalars['UUID'];
  isAdmin: Scalars['Boolean'];
  isTech: Scalars['Boolean'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  salesOfficeIds: Array<Scalars['UUID']>;
};

export type UserYubiKey = {
  __typename?: 'UserYubiKey';
  dateAdded: Scalars['DateTime'];
  id: Scalars['String'];
  lastUsed?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  user: User;
  userId: Scalars['UUID'];
};

export type UserYubiKeyFilterInput = {
  and?: InputMaybe<Array<UserYubiKeyFilterInput>>;
  dateAdded?: InputMaybe<DateTimeOperationFilterInput>;
  lastUsed?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserYubiKeyFilterInput>>;
  user?: InputMaybe<ApplicationUserFilterInput>;
  userId?: InputMaybe<UuidOperationFilterInput>;
  yubiKeyId?: InputMaybe<StringOperationFilterInput>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>;
  gt?: InputMaybe<Scalars['UUID']>;
  gte?: InputMaybe<Scalars['UUID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  lt?: InputMaybe<Scalars['UUID']>;
  lte?: InputMaybe<Scalars['UUID']>;
  neq?: InputMaybe<Scalars['UUID']>;
  ngt?: InputMaybe<Scalars['UUID']>;
  ngte?: InputMaybe<Scalars['UUID']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  nlt?: InputMaybe<Scalars['UUID']>;
  nlte?: InputMaybe<Scalars['UUID']>;
};

export type Version = {
  __typename?: 'Version';
  comment: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['UUID'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  qtyServers: Scalars['Int'];
  servers: Array<Server>;
  signingKey: SigningKey;
  signingKeyId: Scalars['UUID'];
  tokens: Array<LicenseKey>;
  versionString: Scalars['String'];
};

export type VersionAddInput = {
  comment: Scalars['String'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  signingKeyId: Scalars['UUID'];
};

export type VersionFilterInput = {
  and?: InputMaybe<Array<VersionFilterInput>>;
  comment?: InputMaybe<StringOperationFilterInput>;
  created?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<UuidOperationFilterInput>;
  major?: InputMaybe<IntOperationFilterInput>;
  minor?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<VersionFilterInput>>;
  patch?: InputMaybe<IntOperationFilterInput>;
  servers?: InputMaybe<ListFilterInputTypeOfServerFilterInput>;
  signingKey?: InputMaybe<SigningKeyFilterInput>;
  signingKeyId?: InputMaybe<UuidOperationFilterInput>;
  tokens?: InputMaybe<ListFilterInputTypeOfTokenFilterInput>;
};

export type VersionSortInput = {
  comment?: InputMaybe<SortEnumType>;
  created?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  major?: InputMaybe<SortEnumType>;
  minor?: InputMaybe<SortEnumType>;
  patch?: InputMaybe<SortEnumType>;
  signingKey?: InputMaybe<SigningKeySortInput>;
  signingKeyId?: InputMaybe<SortEnumType>;
};

export type VersionUpdateInput = {
  comment: Scalars['String'];
  id: Scalars['UUID'];
  major: Scalars['Int'];
  minor: Scalars['Int'];
  patch: Scalars['Int'];
  signingKeyId: Scalars['UUID'];
};

export type YubiKeyAddInput = {
  name?: InputMaybe<Scalars['String']>;
  otp: Scalars['String'];
  userId: Scalars['UUID'];
};

export type YubiKeyDeleteInput = {
  id: Scalars['String'];
  userId: Scalars['UUID'];
};

export type YubiKeyUpdateInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

export type LicenseKey = {
  __typename?: 'licenseKey';
  expiration: Scalars['DateTime'];
  id: Scalars['UUID'];
  issued: Scalars['DateTime'];
  server: Server;
  serverId: Scalars['UUID'];
  signingKey: SigningKey;
  signingKeyId: Scalars['UUID'];
  version: Version;
  versionId: Scalars['UUID'];
};
