export function dateTimeFormat(
  dateValue?: string,
  emptyText: string = ''
): string {
  if (!dateValue) return emptyText;

  const date = new Date(dateValue);

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}
