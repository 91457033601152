type Handler<T> = (value: T) => void;

/**
 * There are a plethora of libraries out there, but I just want something simple, flexible and typesafe. ~JB
 * Let's hope those aren't "famous last words".
 */
export class EventEmitter<T> {
  private handlers: {
    [eventName in keyof T]?: Handler<T[eventName]>[];
  } = {};

  emit<K extends keyof T>(event: K, value: T[K]): void {
    this.handlers[event]?.forEach((h) => h(value));
  }

  /**
   *
   * @param event
   * @param handler
   * @return function Callback to unsubscribe from the event.
   */
  on<K extends keyof T>(event: K, handler: Handler<T[K]>): () => void {
    if (this.handlers[event]) {
      this.handlers[event]!.push(handler);
    } else {
      this.handlers[event] = [handler];
    }

    return () => this.off(event, handler);
  }

  off<K extends keyof T>(event: K, handler: Handler<T[K]>): void {
    if (!this.handlers[event]) {
      return;
    }

    const k = this.handlers[event]!.findIndex((h) => h === handler);

    if (k !== -1) {
      this.handlers[event]!.splice(k, 1);
    }
  }
}
