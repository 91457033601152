import { gql } from '@apollo/client';

export const featureFragment = gql`
  fragment Feature on Feature {
    id
    name
    hasExpiration
    companiesQty
    packagesQty
    firstVersionId
    firstVersion {
      id
      versionString
    }
    lastVersionId
    lastVersion {
      id
      versionString
    }
  }
`;

export const featuresQuery = gql`
  query Features {
    features {
      ...Feature
    }
  }
  ${featureFragment}
`;

export const featureQuery = gql`
  query Feature($id: UUID!, $isNew: Boolean = false) {
    feature(id: $id) @skip(if: $isNew) {
      ...Feature
      packages {
        id
        name
        serversQty
      }
    }
    versions {
      id
      versionString
    }
  }
  ${featureFragment}
`;

export const featureAddMutation = gql`
  mutation FeatureAdd($input: FeatureAddInput!) {
    featureAdd(input: $input) {
      ...Feature
    }
  }
  ${featureFragment}
`;

export const featureUpdateMutation = gql`
  mutation FeatureUpdate($input: FeatureUpdateInput!) {
    featureUpdate(input: $input) {
      ...Feature
    }
  }
  ${featureFragment}
`;

export const featureRemoveMutation = gql`
  mutation FeatureRemove($id: UUID!) {
    featureRemove(id: $id)
  }
`;
