import { useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../../Page';
import {
  salesOfficeAddMutation,
  salesOfficeQuery,
  salesOfficeRemoveMutation,
  salesOfficesQuery,
  salesOfficeUpdateMutation,
} from './queries';
import {
  SalesOfficeAddMutation,
  SalesOfficeAddMutationVariables,
  SalesOfficeFragment,
  SalesOfficeQuery,
  SalesOfficeQueryVariables,
  SalesOfficeRemoveMutation,
  SalesOfficeRemoveMutationVariables,
  SalesOfficeUpdateMutation,
  SalesOfficeUpdateMutationVariables,
} from './types/queries';

const EditPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<{ id: string }>();
  const isNew = params.id === 'new';

  const [state, setState] = useState<SalesOfficeFragment>(
    {} as SalesOfficeFragment
  );
  const { data, loading } = useQuery<
    SalesOfficeQuery,
    SalesOfficeQueryVariables
  >(salesOfficeQuery, {
    variables: {
      id: params.id,
    },
    skip: isNew,
    onCompleted: (d) => setState(d.salesOffice!),
  });

  const [update, { loading: saving }] = useMutation<
    SalesOfficeUpdateMutation,
    SalesOfficeUpdateMutationVariables
  >(salesOfficeUpdateMutation);
  const [add, { loading: adding }] = useMutation<
    SalesOfficeAddMutation,
    SalesOfficeAddMutationVariables
  >(salesOfficeAddMutation, {
    refetchQueries: [{ query: salesOfficesQuery }],
    awaitRefetchQueries: true,
  });
  const [remove, { loading: removing }] = useMutation<
    SalesOfficeRemoveMutation,
    SalesOfficeRemoveMutationVariables
  >(salesOfficeRemoveMutation, {
    refetchQueries: [{ query: salesOfficesQuery }],
    awaitRefetchQueries: true,
  });

  const isChanging = saving || adding || removing;

  return (
    <Page
      title="Sales Office"
      backButton
      options={
        !loading &&
        !isNew && (
          <Tooltip title="Remove Sales Office">
            <IconButton
              color="error"
              onClick={async () => {
                await remove({ variables: { id: params.id } });
                nav('/admin/sales_offices');
              }}
              disabled={isChanging || (data?.salesOffice?.ownersQty ?? 1) > 0}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Card sx={{ width: '100%', maxWidth: '500px' }}>
          <CardHeader title={data?.salesOffice?.name} />

          <CardContent>
            <TextField
              autoFocus
              label="Name"
              value={state?.name || ''}
              onChange={(e) => setState({ ...state!, name: e.target.value })}
              variant="outlined"
              disabled={isChanging}
              required
            />
          </CardContent>
          <CardActions>
            <LoadingButton
              loading={isChanging}
              variant="contained"
              onClick={async () => {
                if (isNew) {
                  await add({ variables: { name: state!.name } });
                } else {
                  await update({
                    variables: {
                      id: data!.salesOffice!.id,
                      name: state!.name,
                    },
                  });
                }
                nav('/admin/sales_offices');
              }}
            >
              Save
            </LoadingButton>
          </CardActions>
        </Card>
      )}
    </Page>
  );
};

export default EditPage;
