import { gql } from '@apollo/client';

export const whoAmIFragment = gql`
  fragment WhoAmI on User {
    id
    userName
    isAdmin
    isTech
  }
`;

export const whoAmIQuery = gql`
  query WhoAmI {
    whoAmI {
      ...WhoAmI
    }
  }
  ${whoAmIFragment}
`;
