import { gql, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Box,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';
import {
  OwnersListFragment,
  OwnersListQuery,
  OwnersListQueryVariables,
  ServersListQuery,
  ServersListQueryVariables,
} from './types/OwnerServerSelector';

export const ownersQuery = gql`
  fragment OwnersList on Owner {
    id
    name
  }
  query OwnersList {
    owners {
      ...OwnersList
    }
  }
`;

export const serversQuery = gql`
  query ServersList($ownerId: UUID!) {
    owner(id: $ownerId) {
      id
      servers {
        id
        name
        machineId
        companies {
          id
          name
        }
        backupFor {
          id
          name
        }
      }
    }
  }
`;

interface Props {
  skip?: boolean;
  ownerId?: string;
  setOwnerId: (id: string) => void;
  serverId?: string;
  setServerId: (id: string) => void;
  padding?: number;
  disabled?: boolean;
}

const OwnerServerSelector: FC<Props> = ({
  skip = false,
  ownerId,
  setOwnerId,
  serverId,
  setServerId,
  padding = 2,
  disabled = false,
}) => {
  const { data: owners, loading: ownersLoading } = useQuery<
    OwnersListQuery,
    OwnersListQueryVariables
  >(ownersQuery, {
    skip: skip,
  });

  const { data: servers, loading: serversLoading } = useQuery<
    ServersListQuery,
    ServersListQueryVariables
  >(serversQuery, {
    skip: skip || !ownerId,
    variables: {
      ownerId: ownerId,
    },
  });

  const owner = useMemo(
    () => owners?.owners.find((o) => o.id === ownerId),
    [ownerId, owners]
  );

  const server = useMemo(
    () => servers?.owner?.servers.find((s) => s.id === serverId),
    [serverId, servers?.owner?.servers]
  );

  return (
    <>
      <Box sx={{ p: padding, width: '100%' }}>
        <Autocomplete
          value={owner || ({ id: '' } as OwnersListFragment)}
          onChange={(e, val) => setOwnerId((val as OwnersListFragment).id)}
          sx={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} label="Owner" required />
          )}
          options={owners?.owners || []}
          isOptionEqualToValue={(a, b) => a?.id === b?.id}
          disabled={ownersLoading || disabled}
          getOptionLabel={(o) => o.name || ''}
        />
      </Box>
      <Box sx={{ p: padding, width: '100%' }}>
        <TextField
          disabled={!ownerId || serversLoading || disabled}
          required
          sx={{ width: '100%' }}
          select={!!servers?.owner?.servers}
          label="Server"
          value={!servers?.owner?.servers ? '' : serverId}
          onChange={(e) => setServerId(e.target.value)}
        >
          {servers?.owner?.servers.map((s) => (
            <MenuItem
              key={s.id}
              value={s.id}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>{s.name}</div>
              {s.backupFor && <em>Backup For {s.backupFor.name}</em>}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {serverId && server && (
        <Typography
          component="div"
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            pt: 0,
            maxHeight: 350,
            fontSize: '.875rem',
          }}
        >
          Companies in {server.name}
          {server.companies?.length === 0 ? (
            <em>none</em>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                overflowY: 'auto',
              }}
            >
              <ul
                style={{
                  marginTop: 1,
                  marginBottom: 0,
                }}
              >
                {server?.companies?.map((c) => (
                  <li key={c.id}>{c.name}</li>
                ))}
              </ul>
            </Box>
          )}
        </Typography>
      )}
    </>
  );
};

export default OwnerServerSelector;
