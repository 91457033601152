import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useLogout } from '../lib/hooks/useLogout';
import { UserContext } from '../lib/UserContext';

const LoginMenu: FC = () => {
  const { isLoggedIn, user } = useContext(UserContext);
  const { logOut } = useLogout();
  return isLoggedIn ? (
    <>
      <ListItemButton component={NavLink} to="/profile">
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText>{user?.userName}</ListItemText>
      </ListItemButton>
      <ListItemButton onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </ListItemButton>
    </>
  ) : (
    <>
      <ListItemButton component={NavLink} to={'/login'}>
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText>Login</ListItemText>
      </ListItemButton>
    </>
  );
};

export default LoginMenu;
