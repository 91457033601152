import { useMutation, useQuery } from '@apollo/client';
import { Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import Page from 'components/Page';
import { useNavigate, useParams } from 'react-router-dom';
import EditPageCard from 'lib/components/EditPageCard';
import { PartialFragment } from 'lib/gql/typeHelpers';
import { extractError } from 'lib/gql/extractError';
import {
  packageAddMutation,
  packageQuery,
  packageRemoveMutation,
  packageUpdateMutation,
} from './queries';
import {
  PackageAddMutation,
  PackageAddMutationVariables,
  PackageFragment,
  PackageQuery,
  PackageQueryVariables,
  PackageRemoveMutation,
  PackageRemoveMutationVariables,
  PackageUpdateMutation,
  PackageUpdateMutationVariables,
} from './types/queries';

const EditPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<{ id: string }>();
  const isNew = params.id === 'new';

  const [state, setState] = useState<
    PartialFragment<
      PackageFragment,
      'features' | 'serversQty' | 'featuresQty'
    > & { features: string[] }
  >({ features: [] });

  const [error, setError] = useState('');

  const { data, loading } = useQuery<PackageQuery, PackageQueryVariables>(
    packageQuery,
    {
      variables: {
        id: isNew ? '00000000-0000-0000-0000-000000000000' : params.id,
        isNew,
      },
      onCompleted: (data) =>
        setState({
          ...data?.package,
          features: data.package?.features.map((f) => f.id) ?? [],
        }),
    }
  );

  const [add, { loading: adding }] = useMutation<
    PackageAddMutation,
    PackageAddMutationVariables
  >(packageAddMutation, {
    onError: (error) => setError(extractError(error)),
  });

  const [update, { loading: updating }] = useMutation<
    PackageUpdateMutation,
    PackageUpdateMutationVariables
  >(packageUpdateMutation, {
    onError: (error) => setError(extractError(error)),
  });

  const [remove, { loading: removing }] = useMutation<
    PackageRemoveMutation,
    PackageRemoveMutationVariables
  >(packageRemoveMutation, {
    onError: (error) => setError(extractError(error)),
  });

  const isChanging = adding || updating || removing;
  return (
    <Page
      backButton
      title="Edit Package"
      options={
        !isNew && (
          <Tooltip title="Delete Package">
            <span>
              <IconButton
                disabled={loading || (data?.package?.serversQty ?? 0) > 0}
                color="error"
                onClick={async () => {
                  const result = await remove({ variables: { id: state.id } });
                  if (result.data?.packageRemove === true) {
                    nav('/admin/packages');
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <EditPageCard
          title={isNew ? 'New Package' : data?.package?.name}
          error={error}
          actions={
            <LoadingButton
              loading={isChanging}
              variant="contained"
              startIcon={<Save />}
              onClick={async () => {
                if (!state.name) {
                  setError('The name field is required');
                  return;
                }
                setError('');

                if (isNew) {
                  const result = await add({
                    variables: {
                      input: {
                        name: state.name,
                        featureIds: state.features,
                      },
                    },
                  });
                  if (result.data?.packageAdd.id) {
                    nav('/admin/packages');
                  }
                } else {
                  const result = await update({
                    variables: {
                      input: {
                        id: state.id,
                        name: state.name,
                        featureIds: state.features,
                      },
                    },
                  });
                  if (result.data?.packageUpdate.id) {
                    nav('/admin/packages');
                  }
                }
              }}
            >
              Save
            </LoadingButton>
          }
        >
          <TextField
            required
            label="Name"
            disabled={isChanging}
            value={state.name || ''}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
          <Box>
            <Typography mb={1}>Included Features</Typography>
            {data?.features.map((f) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body1">{f.name}</Typography>
                <Checkbox
                  disabled={isChanging}
                  checked={state.features.includes(f.id)}
                  onChange={(e) =>
                    setState({
                      ...state,
                      features: e.target.checked
                        ? [...state.features, f.id]
                        : state.features.filter((fid) => fid !== f.id),
                    })
                  }
                />
              </Box>
            ))}
          </Box>
        </EditPageCard>
      )}
    </Page>
  );
};

export default EditPage;
