import { gql } from '@apollo/client';

export const yubiKeyFragment = gql`
  fragment YubiKey on UserYubiKey {
    id
    name
    lastUsed
    dateAdded
  }
`;

const userFragment = gql`
  fragment User on User {
    id
    userName
    email
    isAdmin
    isTech
    twoFactorEnabled
    salesOffices {
      id
      name
    }
  }
`;

export const usersQuery = gql`
  query Users {
    users {
      ...User
    }
  }
  ${userFragment}
`;

export const userQuery = gql`
  query User($id: UUID!) {
    user(id: $id) {
      ...User
      yubiKeys {
        ...YubiKey
      }
    }
  }
  ${userFragment}
  ${yubiKeyFragment}
`;

export const salesOfficesQuery = gql`
  query SalesOfficeList {
    salesOffices(order: { name: ASC }) {
      id
      name
    }
  }
`;

export const userAddMutation = gql`
  mutation UserAdd($input: UserAddInput!) {
    userAdd(input: $input) {
      ...User
    }
  }
  ${userFragment}
`;

export const userUpdateMutation = gql`
  mutation UpdateUser($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      ...User
    }
  }
  ${userFragment}
`;

export const usersRemoveMutation = gql`
  mutation UsersRemove($id: UUID!) {
    userRemove(id: $id)
  }
`;

export const deleteYubiKeyMutation = gql`
  mutation UserYubiKeyDelete($input: YubiKeyDeleteInput!) {
    userYubiKeyDelete(input: $input)
  }
`;
