import { useMutation } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { FC } from 'react';
import { dateTimeFormat } from 'lib/formatters';
import GridWrapper from 'lib/GridWrapper';
import { profileQuery } from '../Profile/queries';
import { deleteYubiKeyMutation, userQuery } from './queries';
import {
  UserYubiKeyDeleteMutation,
  UserYubiKeyDeleteMutationVariables,
  YubiKeyFragment,
} from './types/queries';

interface Props {
  userId: string;
  keys: YubiKeyFragment[];
}

const YubiKeysTable: FC<Props> = ({ userId, keys }) => {
  const [deleteYubiKey, { loading: deletingYubiKey }] = useMutation<
    UserYubiKeyDeleteMutation,
    UserYubiKeyDeleteMutationVariables
  >(deleteYubiKeyMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: userQuery, variables: { id: userId } },
      { query: profileQuery },
    ],
  });
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false,
      width: 100,
    },
    {
      field: 'lastUsed',
      headerName: 'Last Used',
      width: 175,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => dateTimeFormat(value, 'never'),
    },
    {
      field: 'dateAdded',
      headerName: 'Date Added',
      width: 175,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => dateTimeFormat(value),
    },
    {
      field: '__typename',
      headerName: '',
      width: 50,
      renderCell: (props) => (
        <IconButton
          size="small"
          color="error"
          disabled={deletingYubiKey}
          onClick={async () => {
            await deleteYubiKey({
              variables: { input: { userId: userId, id: props.row.id } },
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h6">Associated YubiKeys</Typography>
      <Box sx={{ flexGrow: 1 }}>
        <GridWrapper
          columns={columns}
          rows={keys}
          loading={false}
          basePath={false}
        />
      </Box>
    </Box>
  );
};

export default YubiKeysTable;
