import { useQuery } from '@apollo/client';
import { IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import GridWrapper from 'lib/GridWrapper';
import Page from '../../Page';
import { OwnerFragment, OwnersQuery } from './types/queries';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import { ownersQuery } from './queries';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'salesOffice',
    valueGetter: (params) =>
      (params.value as OwnerFragment['salesOffice']).name,
    headerName: 'Sales Office',
    width: 250,
  },
  {
    field: 'serversQty',
    headerName: 'Servers',
    width: 100,
    align: 'right',
  },
  {
    field: 'companiesQty',
    headerName: 'Companies',
    width: 100,
    align: 'right',
  },
  {
    field: 'usersQty',
    headerName: 'Users',
    width: 100,
    align: 'right',
  },
];

const OwnersPage: FC & { title: string } = () => {
  const nav = useNavigate();
  const { data, loading } = useQuery<OwnersQuery>(ownersQuery);

  return (
    <Page
      options={
        <Tooltip title="Add owner">
          <IconButton
            style={{
              color: 'white',
            }}
            onClick={() => nav('/admin/owners/new')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
      title="Owners"
    >
      <GridWrapper
        columns={columns}
        rows={data?.owners ?? []}
        loading={loading}
        basePath={'/admin/owners/'}
      />
    </Page>
  );
};

OwnersPage.title = 'Owners';

export default OwnersPage;
