import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Box,
  IconButton,
  LinearProgress,
  Toolbar,
  Typography,
} from '@mui/material';
import { FC, ReactNode, useContext } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../lib/UserContext';
import { drawerWidth } from './Layout';

interface Props {
  children?: any;
  title: string;
  showAppBar?: boolean;
  options?: ReactNode;
  backButton?: boolean | string;
  requireAuth?: boolean;
}

const Page: FC<Props> = ({
  title,
  backButton,
  options,
  children,
  requireAuth = true,
  showAppBar = true,
}) => {
  const nav = useNavigate();
  const location = useLocation();
  const { isLoggedIn, loading } = useContext(UserContext);

  if (requireAuth) {
    if (loading) {
      children = <LinearProgress />;
    } else {
      if (!isLoggedIn) {
        const returnUrl = `${location.pathname}${location.search}${location.hash}`;

        return (
          <Navigate
            to={{
              pathname: '/login',
              search: `returnUrl=${encodeURIComponent(returnUrl)}`,
            }}
          />
        );
      }
    }
  }

  return (
    <>
      {showAppBar && (
        <AppBar
          position="fixed"
          sx={{
            width: `calc(100% - ${drawerWidth}px)`,
            ml: `${drawerWidth}px`,
          }}
        >
          <Toolbar>
            <Box sx={{ mr: 2, width: 36 }}>
              {backButton && (
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onMouseDown={() => {
                    if (backButton !== true) {
                      nav(backButton as string);
                    } else {
                      nav(-1);
                    }
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {!title ? 'Galaxy' : title}
            </Typography>
            {options}
          </Toolbar>
        </AppBar>
      )}
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
        }}
      >
        <Toolbar />
        <Box sx={{ height: 'calc(100% - 65px)' }}>{children}</Box>
      </Box>
    </>
  );
};

export default Page;
