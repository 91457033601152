import { gql } from '@apollo/client';

export const salesOfficeFragment = gql`
  fragment SalesOffice on SalesOffice {
    id
    name
    ownersQty
    usersQty
  }
`;
export const salesOfficesQuery = gql`
  query SalesOffices {
    salesOffices(order: { name: ASC }) {
      ...SalesOffice
    }
  }
  ${salesOfficeFragment}
`;

export const salesOfficeQuery = gql`
  query SalesOffice($id: UUID!) {
    salesOffice(id: $id) {
      ...SalesOffice
    }
  }
  ${salesOfficeFragment}
`;

export const salesOfficeUpdateMutation = gql`
  mutation SalesOfficeUpdate($id: UUID!, $name: String!) {
    salesOfficeUpdate(input: { id: $id, name: $name }) {
      ...SalesOffice
    }
  }
  ${salesOfficeFragment}
`;

export const salesOfficeAddMutation = gql`
  mutation SalesOfficeAdd($name: String!) {
    salesOfficeAdd(input: { name: $name }) {
      ...SalesOffice
    }
  }
  ${salesOfficeFragment}
`;

export const salesOfficeRemoveMutation = gql`
  mutation SalesOfficeRemove($id: UUID!) {
    salesOfficeRemove(id: $id)
  }
`;
