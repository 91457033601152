import { ApolloError, ServerError } from '@apollo/client';
import { GraphQLError } from 'graphql';

function isServerError(networkError: any): networkError is ServerError {
  return !!networkError?.result;
}
function isGraphQLError(graphQLErrors: any): graphQLErrors is GraphQLError {
  return !!graphQLErrors;
}

export function extractError(error: ApolloError): string {
  if (isServerError(error.networkError)) {
    const result = error.networkError.result as {
      errors?: [{ message: string }];
    };
    return result.errors ? result.errors.map((e) => e.message).join(', ') : '';
  } else if (isGraphQLError(error.graphQLErrors)) {
    return error.graphQLErrors.map((e) => e.message).join(', ');
  }

  return error.message;
}
