import { gql } from '@apollo/client';

const ownerFragment = gql`
  fragment Owner on Owner {
    id
    name
    email
    companiesQty
    usersQty
    serversQty
    salesOfficeId
    salesOffice {
      id
      name
    }
  }
`;

const ownerDetailFragment = gql`
  fragment CompanyFeature on CompanyFeature {
    featureId
    expiration
  }
  fragment Company on Company {
    id
    name
    localCompanyName
    allocatedUsers
    companyFeatures {
      ...CompanyFeature
    }
  }
  fragment Server on Server {
    id
    name
    localName
    lastSeen
    machineId
    companies {
      ...Company
    }
    backupFor {
      id
    }
    licenseKeysQty
    version {
      id
      versionString
    }
    packages {
      id
      name
    }
  }
  fragment OwnerDetails on Owner {
    ...Owner
    servers {
      ...Server
    }
  }
  ${ownerFragment}
`;

export const ownerQuery = gql`
  query Owner($id: UUID!) {
    owner(id: $id) {
      ...OwnerDetails
    }
  }
  ${ownerDetailFragment}
`;

export const recentLicenseKeysQuery = gql`
  query RecentLicenseKeys($serverId: UUID!) {
    licenseKeysRecent(serverId: $serverId) {
      id
      issued
      expiration
      version {
        id
        versionString
      }
    }
  }
`;

export const ownersQuery = gql`
  query Owners {
    owners {
      ...Owner
    }
  }
  ${ownerFragment}
`;

export const ownerUpdateMutation = gql`
  mutation OwnerUpdate($input: OwnerUpdateInput!) {
    ownerUpdate(input: $input) {
      ...OwnerDetails
    }
  }
  ${ownerDetailFragment}
`;

export const ownerAddMutation = gql`
  mutation OwnerAdd($input: OwnerAddInput!) {
    ownerAdd(input: $input) {
      ...OwnerDetails
    }
  }
  ${ownerDetailFragment}
`;

export const ownerRemoveMutation = gql`
  mutation OwnerRemove($id: UUID!) {
    ownerRemove(id: $id)
  }
`;
