import { gql } from '@apollo/client';
import { featureFragment } from '../features/queries';

export const packagesQuery = gql`
  query Packages($minimal: Boolean = false) {
    packages {
      id
      name
      featuresQty @skip(if: $minimal)
      serversQty @skip(if: $minimal)
    }
  }
`;

const packageFragment = gql`
  fragment Package on Package {
    id
    name
    serversQty
    featuresQty
    features {
      ...Feature
    }
  }
  ${featureFragment}
`;

export const packageQuery = gql`
  query Package($id: UUID!, $isNew: Boolean = false) {
    package(id: $id) @skip(if: $isNew) {
      ...Package
    }
    features(where: { hasExpiration: { eq: false } }) {
      ...Feature
    }
  }
  ${packageFragment}
`;

export const packageAddMutation = gql`
  mutation PackageAdd($input: PackageAddInput!) {
    packageAdd(input: $input) {
      ...Package
    }
  }
  ${packageFragment}
`;

export const packageUpdateMutation = gql`
  mutation PackageUpdate($input: PackageUpdateInput!) {
    packageUpdate(input: $input) {
      ...Package
    }
  }
  ${packageFragment}
`;

export const packageRemoveMutation = gql`
  mutation PackageRemove($id: UUID!) {
    packageRemove(id: $id)
  }
`;
