import { useQuery } from '@apollo/client';
import { CopyAll } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';
import { PartialFragment } from 'lib/gql/typeHelpers';
import { featuresQuery } from '../../features/queries';
import { FeatureFragment, FeaturesQuery } from '../../features/types/queries';
import { CompanyFragment } from '../types/queries';
import { FeatureEdit, StateCompanyFeaturesType } from './FeatureEdit';
import { FeatureInfo } from './FeatureInfo';

export type StateCompaniesType = PartialFragment<
  CompanyFragment,
  'companyFeatures'
> & {
  companyFeatures?: StateCompanyFeaturesType[];
};

interface CompanyEditProps {
  company: StateCompaniesType;
  setState: (company: StateCompaniesType) => void;
  removeCompany: () => void;
  disabled: boolean;
}

export const CompanyEdit: FC<CompanyEditProps> = ({
  company,
  setState,
  disabled,
  removeCompany,
}) => {
  const { data: features, loading } = useQuery<FeaturesQuery>(featuresQuery);

  const featuresDictionary = useMemo(() => {
    const dict = new Map<string, FeatureFragment>();
    features?.features.forEach((v) => dict.set(v.id, v));
    return dict;
  }, [features?.features]);

  if (loading || !features) {
    return <LinearProgress />;
  }

  return (
    <Accordion
      sx={{ backgroundColor: 'grey.100' }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          pt: 1,
          display: 'flex',
          '.MuiFormControl-root': { mb: 0 },
        }}
        tabIndex={-1}
      >
        <TextField
          required
          disabled={disabled}
          label="Company Name"
          variant="outlined"
          sx={{ width: 250 }}
          value={company.name || ''}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            setState({ ...company, name: e.target.value });
          }}
          helperText={
            company.localCompanyName
              ? 'Local Name: ' + company.localCompanyName
              : null
          }
          InputProps={{
            endAdornment:
              company.localCompanyName &&
              company.localCompanyName !== company.name ? (
                <InputAdornment position="end" variant="filled">
                  <Tooltip title="Copy Local Name">
                    <IconButton
                      onClick={() =>
                        setState({
                          ...company,
                          name: company.localCompanyName!,
                        })
                      }
                    >
                      <CopyAll />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
        />
        <TextField
          disabled={disabled}
          value={company.allocatedUsers || 0}
          label="Allowed Users"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            if (isNaN(parseFloat(e.target.value))) {
              setState({ ...company, allocatedUsers: 0 });
            } else {
              setState({
                ...company,
                allocatedUsers: parseFloat(e.target.value),
              });
            }
          }}
          type="number"
          sx={{ width: 125, ml: 2 }}
        />
        <Typography
          variant="body2"
          component="div"
          sx={{ flexGrow: 1, display: 'inline-block', ml: 2 }}
        >
          {company.companyFeatures &&
            company.companyFeatures.map((f) => (
              <FeatureInfo
                key={f.featureId}
                feature={featuresDictionary.get(f.featureId)!}
                companyFeature={f}
              />
            ))}
        </Typography>
        <Box sx={{ ml: 2, mt: 1, mr: 4, display: 'inline-block' }}>
          <IconButton
            tabIndex={-1}
            disabled={disabled}
            color="error"
            onClick={(e) => {
              e.stopPropagation();
              removeCompany();
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0' }}>
        <Divider />
        {features.features.map((feature) => {
          const index = company.companyFeatures?.findIndex(
            (cf) => cf.featureId === feature.id
          );
          const companyFeature =
            index !== undefined && index >= 0
              ? company.companyFeatures?.at(index)
              : undefined;
          return (
            <FeatureEdit
              key={feature.id}
              disabled={disabled}
              feature={feature}
              companyFeature={companyFeature}
              setState={(newCF) =>
                setState({
                  ...company,
                  companyFeatures: company.companyFeatures?.map((cf) =>
                    cf.featureId === newCF.featureId ? newCF : cf
                  ),
                })
              }
              setActive={(active) => {
                const features: StateCompanyFeaturesType[] = [
                  ...(company.companyFeatures || []),
                ];

                if (active) {
                  if (index === -1) {
                    features.push({ featureId: feature.id });
                  }
                } else {
                  if (index !== undefined && index >= 0) {
                    features.splice(index, 1);
                  }
                }

                setState({ ...company, companyFeatures: features });
              }}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
