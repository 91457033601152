import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FC, StrictMode } from 'react';
import { RouterProvider } from 'react-router-dom';
import { UserContextProvider } from './lib/UserContext';
import { router } from './config/router';
import { client } from './lib/gql/ApolloClient';

import './custom.css';
export const App: FC<{ baseUrl: string }> = ({ baseUrl }) => (
  <StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ApolloProvider client={client}>
        <UserContextProvider>
          <RouterProvider router={router} />
        </UserContextProvider>
      </ApolloProvider>
    </LocalizationProvider>
  </StrictMode>
);
