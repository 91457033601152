import { gql, useMutation } from '@apollo/client';
import { CopyAll, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import EditPageCard from 'lib/components/EditPageCard';
import OwnerServerSelector from 'lib/components/OwnerServerSelector';
import { useParseQueryString } from 'lib/hooks/useParseQueryString';
import { useNavigate } from 'react-router-dom';
import { extractError } from '../../../lib/gql/extractError';
import Page from '../../Page';
import {
  GenerateLicenseMutation,
  GenerateLicenseMutationVariables,
} from './types';

const generateLicenseMutation = gql`
  mutation GenerateLicense($input: LicenseKeyGenerateInput!) {
    licenseKeyGenerate(input: $input)
  }
`;

const GenerateLicensePage: FC = () => {
  const nav = useNavigate();
  const params = useParseQueryString<{
    ownerId?: string;
    serverId?: string;
    machineId?: string;
  }>();
  const [machineId, setMachineId] = useState(params.machineId ?? '');
  const [version, setVersion] = useState('');
  const [validYears, setValidYears] = useState('0Y');
  const [validMonths, setValidMonths] = useState('6M');
  const [ownerId, setOwnerId] = useState(params.ownerId || '');
  const [serverId, setServerId] = useState(params.serverId || '');
  const [error, setError] = useState<string>();

  const [licenses, setLicenses] = useState<string[]>();

  const [generate, { loading: generating }] = useMutation<
    GenerateLicenseMutation,
    GenerateLicenseMutationVariables
  >(generateLicenseMutation, {
    onError: (error) => setError(extractError(error)),
  });

  const onGenerate = useCallback(async () => {
    setError('');
    if (!machineId) {
      setError('The Machine ID is required');
      return;
    }
    if (!version) {
      setError('The Version Number is required');
      return;
    }
    if (!ownerId || !serverId) {
      setError('The Owner and Server must be selected.');
      return;
    }
    if (validYears === '0Y' && validMonths === '0M') {
      setError('The Valid Duration can not be no years and no months.');
      return;
    }

    const response = await generate({
      variables: {
        input: {
          machineId,
          version,
          serverId,
          validTimeSpan: `P${validYears}${validMonths}`,
        },
      },
    });

    if (!response.data) {
      return;
    }

    setLicenses(response.data.licenseKeyGenerate);
  }, [
    generate,
    machineId,
    ownerId,
    serverId,
    validMonths,
    validYears,
    version,
  ]);

  const disabled = generating || !!licenses;

  return (
    <Page title="Generate License Key">
      <EditPageCard title="Manual" error={error} sx={{ width: 500 }} fullWidth>
        <TextField
          label="Machine ID"
          value={machineId}
          onChange={(e) => setMachineId(e.target.value)}
          disabled={disabled}
          required
        />
        <TextField
          label="Version"
          value={version}
          onChange={(e) => setVersion(e.target.value)}
          disabled={disabled}
          required
        />

        <Box>
          <Typography variant="caption">Valid Duration</Typography>
          <Box mt={1}>
            <TextField
              label="Years"
              required
              select
              disabled={disabled}
              sx={{ minWidth: 100, mr: 1 }}
              value={validYears}
              onChange={(e) => setValidYears(e.target.value)}
            >
              <MenuItem value="0Y">None</MenuItem>
              <MenuItem value="1Y">1</MenuItem>
              <MenuItem value="2Y">2</MenuItem>
              <MenuItem value="3Y">3</MenuItem>
              <MenuItem value="4Y">4</MenuItem>
              <MenuItem value="5Y">5</MenuItem>
              <MenuItem value="6Y">6</MenuItem>
              <MenuItem value="99Y">99</MenuItem>
            </TextField>
            <TextField
              label="Months"
              required
              select
              disabled={disabled}
              sx={{ minWidth: 100, mr: 1 }}
              value={validMonths}
              onChange={(e) => setValidMonths(e.target.value)}
            >
              <MenuItem value="0M">None</MenuItem>
              <MenuItem value="1M">1</MenuItem>
              <MenuItem value="2M">2</MenuItem>
              <MenuItem value="3M">3</MenuItem>
              <MenuItem value="4M">4</MenuItem>
              <MenuItem value="5M">5</MenuItem>
              <MenuItem value="6M">6</MenuItem>
              <MenuItem value="7M">7</MenuItem>
              <MenuItem value="8M">8</MenuItem>
              <MenuItem value="9M">9</MenuItem>
              <MenuItem value="10M">10</MenuItem>
              <MenuItem value="11M">11</MenuItem>
            </TextField>
          </Box>
        </Box>

        <OwnerServerSelector
          ownerId={ownerId}
          setOwnerId={setOwnerId}
          serverId={serverId}
          setServerId={setServerId}
          padding={0}
          disabled={disabled}
        />
        <Box>
          <LoadingButton
            loading={generating}
            variant="contained"
            startIcon={<Save />}
            onClick={onGenerate}
            disabled={!!licenses}
          >
            Generate License Keys
          </LoadingButton>
        </Box>
        {licenses && (
          <>
            <Box mt={2} mb={2}>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                onClick={() => {
                  setError('');
                  setMachineId('');
                  setVersion('');
                  setOwnerId('');
                  setServerId('');
                  setLicenses(undefined);
                  nav('/admin/generate_license', { replace: true });
                }}
              >
                Reset
              </Button>
            </Box>
            <Typography variant="h5">License Keys</Typography>
            <div>
              <Button
                variant="outlined"
                startIcon={<CopyAll />}
                onClick={() =>
                  navigator.clipboard.writeText(licenses?.join('\n'))
                }
              >
                Copy Keys
              </Button>
            </div>
            <Box
              sx={{
                mt: 1,
                backgroundColor: '#ededed',
                p: 2,
                whiteSpace: 'pre',
                fontFamily: 'monospace',
                fontSize: 14,
                borderRadius: 2,
                overflowX: 'scroll',
              }}
            >
              {licenses.join('\n')}
            </Box>
          </>
        )}
      </EditPageCard>
    </Page>
  );
};

export default GenerateLicensePage;
