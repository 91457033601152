import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import GridWrapper from 'lib/GridWrapper';
import { dateTimeFormat } from 'lib/formatters';
import Page from '../../Page';
import { versionsQuery } from './queries';
import { VersionsQuery, VersionsQueryVariables } from './types/queries';

const columns: GridColDef[] = [
  {
    field: 'versionString',
    headerName: 'Version',
    width: 150,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 400,
  },
  { field: 'qtyServers', headerName: 'Qty Servers', width: 100 },
  {
    field: 'created',
    headerName: 'Created',
    width: 175,
    valueGetter: (v) => dateTimeFormat(v.value),
  },
  {
    field: 'signingKey',
    headerName: 'Signing Key',
    width: 250,
    renderCell: (params) => {
      return (
        <NavLink
          to={`/admin/signing_keys/${params.value.id}`}
          onClick={(e) => {
            // Stop propigation so that the rowClick event on the table doesn't navigate us to the version.
            e.stopPropagation()
          }}
        >
          {params.value.name}
        </NavLink>
      );
    },
  },
];

const VersionsPage: FC = () => {
  const nav = useNavigate();
  const { data, loading } = useQuery<VersionsQuery, VersionsQueryVariables>(
    versionsQuery
  );

  return (
    <Page
      title="Versions"
      options={
        <Tooltip title="Add Version">
          <span>
            <IconButton
              sx={{ color: 'white' }}
              onClick={() => nav('/admin/versions/new')}
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
      }
    >
      <GridWrapper
        columns={columns}
        rows={data?.versions || []}
        loading={loading}
        basePath="/admin/versions/"
      />
    </Page>
  );
};

export default VersionsPage;
