import { gql } from '@apollo/client';

const versionFragment = gql`
  fragment Version on Version {
    id
    major
    minor
    patch
    comment
    signingKeyId
    versionString
    qtyServers
    servers {
      id
      name
      lastSeen
      owner {
        id
        name
      }
    }
  }
`;

export const versionsQuery = gql`
  query Versions {
    versions {
      id
      versionString
      comment
      created
      qtyServers
      signingKey {
        id
        name
      }
    }
  }
`;

export const versionQuery = gql`
  query Version($id: UUID!) {
    version(id: $id) {
      ...Version
    }
  }
  ${versionFragment}
`;

export const versionServersQuery = gql`
  query VersionServers($id: UUID!) {
    version(id: $id) {
      ...Version
    }
  }
`;

export const versionUpdateMutation = gql`
  mutation VersionUpdate($input: VersionUpdateInput!) {
    versionUpdate(input: $input) {
      ...Version
    }
  }
  ${versionFragment}
`;

export const versionAddMutation = gql`
  mutation VersionAdd($input: VersionAddInput!) {
    versionAdd(input: $input) {
      ...Version
    }
  }
  ${versionFragment}
`;

export const versionRemoveMutation = gql`
  mutation VersionRemove($id: UUID!) {
    versionRemove(id: $id)
  }
`;
