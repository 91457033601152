import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import GridWrapper from 'lib/GridWrapper';
import Page from 'components/Page';
import { packagesQuery } from './queries';
import { PackagesQuery, PackagesQueryVariables } from './types/queries';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
  },
  {
    field: 'featuresQty',
    headerName: 'Features',
    filterable: false,
    width: 150,
  },
  {
    field: 'serversQty',
    headerName: 'Servers',
    filterable: false,
    width: 150,
  },
];

const PackagesPage: FC = () => {
  const nav = useNavigate();
  const { data, loading } = useQuery<PackagesQuery, PackagesQueryVariables>(
    packagesQuery
  );

  return (
    <Page
      title="Packages"
      options={
        <Tooltip title="New Package">
          <IconButton
            style={{ color: 'white' }}
            onClick={() => nav('/admin/packages/new')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
    >
      <GridWrapper
        columns={columns}
        rows={data?.packages || []}
        loading={loading}
        basePath="/admin/packages/"
      />
    </Page>
  );
};

export default PackagesPage;
