import { useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import GridWrapper from 'lib/GridWrapper';
import { dateTimeFormat } from 'lib/formatters';
import Page from '../../Page';
import { signingKeysQuery } from './queries';
import { SigningKeysQuery } from './types/queries';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 310,
  },
  {
    field: 'qtyLicenseKeys',
    headerName: 'Qty License Keys',
    width: 100,
  },
  { field: 'qtyVersions', headerName: 'Qty Versions', width: 100 },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    valueGetter: (v) => dateTimeFormat(v.value),
  },
  {
    field: 'expiration',
    headerName: 'Expiration',
    width: 180,
    valueGetter: (v) => dateTimeFormat(v.value),
  },
];

const SigningKeysPage: FC = () => {
  const nav = useNavigate();
  const { data, loading } = useQuery<SigningKeysQuery>(signingKeysQuery, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Page
      options={
        <Tooltip title="New Signing Key">
          <IconButton
            style={{
              color: 'white',
            }}
            onClick={() => nav('/admin/signing_keys/new')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
      title="Signing Keys"
    >
      <GridWrapper
        columns={columns}
        rows={data?.signingKeys || []}
        loading={loading}
        basePath={'/admin/signing_keys/'}
      />
    </Page>
  );
};

export default SigningKeysPage;
