import { useMutation, useQuery } from '@apollo/client';
import { Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import EditPageCard from 'lib/components/EditPageCard';
import { extractError } from 'lib/gql/extractError';
import { PartialFragment } from 'lib/gql/typeHelpers';
import Page from 'components/Page';
import {
  featureAddMutation,
  featureQuery,
  featureRemoveMutation,
  featuresQuery,
  featureUpdateMutation,
} from './queries';
import {
  FeatureAddMutation,
  FeatureAddMutationVariables,
  FeatureFragment,
  FeatureQuery,
  FeatureQueryVariables,
  FeatureRemoveMutation,
  FeatureRemoveMutationVariables,
  FeatureUpdateMutation,
  FeatureUpdateMutationVariables,
} from './types/queries';

const EditPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<{ id: string }>();
  const isNew = params.id === 'new';

  const [state, setState] = useState<PartialFragment<FeatureFragment>>({});

  const [error, setError] = useState('');

  const { data, loading } = useQuery<FeatureQuery, FeatureQueryVariables>(
    featureQuery,
    {
      variables: {
        id: isNew ? '00000000-0000-0000-0000-000000000000' : params.id,
        isNew,
      },
      onCompleted: (data) => setState({ ...data.feature }),
    }
  );

  const [add, { loading: adding }] = useMutation<
    FeatureAddMutation,
    FeatureAddMutationVariables
  >(featureAddMutation, {
    refetchQueries: [{ query: featuresQuery }],
    awaitRefetchQueries: true,
    onError(error) {
      setError(extractError(error));
    },
  });

  const [update, { loading: updating }] = useMutation<
    FeatureUpdateMutation,
    FeatureUpdateMutationVariables
  >(featureUpdateMutation, {
    refetchQueries: [{ query: featuresQuery }],
    awaitRefetchQueries: true,
    onError(error) {
      setError(extractError(error));
    },
  });

  const [remove, { loading: removing }] = useMutation<
    FeatureRemoveMutation,
    FeatureRemoveMutationVariables
  >(featureRemoveMutation, {
    refetchQueries: [{ query: featuresQuery }],
    awaitRefetchQueries: true,
    onError(error) {
      setError(extractError(error));
    },
  });

  const isChanging = adding || updating || removing;
  return (
    <Page
      title="Edit Feature"
      backButton="/admin/features"
      options={
        !isNew && (
          <Tooltip title="Delete Feature">
            <span>
              <IconButton
                disabled={
                  loading ||
                  isChanging ||
                  (data?.feature?.companiesQty ?? 0) > 0 ||
                  (data?.feature?.packagesQty ?? 0) > 0
                }
                color="error"
                onClick={async () => {
                  const result = await remove({ variables: { id: state.id } });
                  if (result.data?.featureRemove === true) {
                    nav('/admin/features');
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <EditPageCard
          error={error}
          title={isNew ? state.name ?? 'New Feature' : data?.feature?.name}
          actions={
            <LoadingButton
              loading={isChanging}
              variant="contained"
              startIcon={<Save />}
              onClick={async () => {
                if (!state.name) {
                  setError('The name field is required');
                  return;
                }

                setError('');

                if (isNew) {
                  const result = await add({
                    variables: {
                      input: {
                        name: state.name,
                        firstVersionId: state.firstVersionId,
                        lastVersionId: state.lastVersionId,
                        hasExpiration: !!state.hasExpiration,
                      },
                    },
                  });
                  if (result.data?.featureAdd.id) {
                    nav('/admin/features');
                  }
                } else {
                  const result = await update({
                    variables: {
                      input: {
                        id: state.id,
                        name: state.name,
                        firstVersionId: state.firstVersionId,
                        lastVersionId: state.lastVersionId,
                        hasExpiration: !!state.hasExpiration,
                      },
                    },
                  });
                  if (result.data?.featureUpdate.id) {
                    nav('/admin/features');
                  }
                }
              }}
            >
              Save
            </LoadingButton>
          }
        >
          <TextField
            required
            sx={{ width: 465 }}
            label="Name"
            disabled={isChanging}
            value={state.name || ''}
            onChange={(e) => setState({ ...state, name: e.target.value })}
          />
          <VersionSelector
            label="First Version"
            disabled={isChanging}
            value={state.firstVersionId}
            setValue={(v) => setState({ ...state, firstVersionId: v })}
            versions={data?.versions}
          />
          <VersionSelector
            label="Last Version"
            disabled={isChanging}
            value={state.lastVersionId}
            setValue={(v) => setState({ ...state, lastVersionId: v })}
            versions={data?.versions}
          />
          <FormControlLabel
            disabled={isChanging}
            label="Expires"
            control={
              <Checkbox
                checked={!!state.hasExpiration}
                onChange={(e) =>
                  setState({ ...state, hasExpiration: e.target.checked })
                }
              />
            }
          />
        </EditPageCard>
      )}
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Packages
      </Typography>
      <TableContainer component={Paper} sx={{ width: 300 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Package</TableCell>
              <TableCell>Qty Servers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.feature?.packages === undefined ||
            data?.feature?.packages.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4}>No Packages</TableCell>
              </TableRow>
            ) : (
              <>
                {data.feature.packages.map((p) => (
                  <TableRow>
                    <TableCell>
                      <NavLink to={`/admin/packages/${p.id}`}>{p.name}</NavLink>
                    </TableCell>
                    <TableCell>{p.serversQty}</TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export default EditPage;

interface VersionSelectorProps {
  versions?: FeatureQuery['versions'];
  disabled: boolean;
  value: string | null;
  setValue: (value: string | null) => void;
  label: string;
}

const NoVersion = 'none';
const VersionSelector: FC<VersionSelectorProps> = ({
  value,
  disabled,
  label,
  setValue,
  versions,
}) => {
  return (
    <TextField
      select
      label={label}
      value={value ?? NoVersion}
      onChange={(e) =>
        setValue(e.target.value === NoVersion ? null : e.target.value)
      }
      disabled={disabled}
    >
      <MenuItem value={NoVersion}>None</MenuItem>
      {versions?.map((v) => (
        <MenuItem key={v.id} value={v.id}>
          {v.versionString}
        </MenuItem>
      ))}
    </TextField>
  );
};
