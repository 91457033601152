import { useQuery } from '@apollo/client';
import { Edit, History, Key } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { formatDistance } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import { PartialFragment } from 'lib/gql/typeHelpers';
import { dateTimeFormat } from 'lib/formatters';
import { NavLink } from 'react-router-dom';
import { packagesQuery } from '../../Packages/queries';
import {
  PackagesQuery,
  PackagesQueryVariables,
} from '../../Packages/types/queries';
import { recentLicenseKeysQuery } from '../queries';
import {
  RecentLicenseKeysQuery,
  RecentLicenseKeysQueryVariables,
  ServerFragment,
} from '../types/queries';
import { CompanyEdit, StateCompaniesType } from './CompanyEdit';

export type StateServerType = PartialFragment<ServerFragment, 'companies'> & {
  companies?: StateCompaniesType[] | null;
  backupFor?: PartialFragment<ServerFragment['backupFor']> | null;
  packageIds: string[];
};

interface Props {
  ownerId?: string;
  server: StateServerType;
  servers: StateServerType[];
  setState: (server: StateServerType) => void;
  removeServer: () => void;
  disabled: boolean;
  qtyCompanies: number;
}

const ServerEdit: FC<Props> = ({
  ownerId,
  server,
  setState,
  removeServer,
  servers,
  disabled,
  qtyCompanies,
}) => {
  const [addQty, setAddQty] = useState(1);

  const handleAddCompanies = useCallback(() => {
    if (addQty !== 10) {
      setAddQty(1);
    }

    const companies = [...(server.companies ?? [])];
    for (let i = qtyCompanies; i < qtyCompanies + addQty; i++) {
      companies.push({
        name: `Company ${i + 1}`,
        allocatedUsers: 1,
      });
    }
    setState({
      ...server,
      companies,
    });
  }, [addQty, qtyCompanies, server, setState]);

  const [expanded, setExpanded] = useState(false);
  const [keysExpanded, setKeysExpanded] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { data: packages } = useQuery<PackagesQuery, PackagesQueryVariables>(
    packagesQuery,
    { variables: { minimal: true }, skip: !editMode }
  );

  const { data: recentLicenses, loading } = useQuery<
    RecentLicenseKeysQuery,
    RecentLicenseKeysQueryVariables
  >(recentLicenseKeysQuery, {
    skip: !keysExpanded,
    fetchPolicy: 'cache-and-network',
    variables: {
      serverId: server.id,
    },
  });

  const isBackupServer = !!server.backupFor?.id;

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded}
        onChange={() => setExpanded(isBackupServer ? false : !expanded)}
      >
        <AccordionSummary
          sx={{
            cursor: isBackupServer ? 'default !important' : undefined,
            '.Mui-expanded': { mt: '12px', mb: '12px' },
            '.MuiAccordionSummary-expandIconWrapper': {
              alignSelf: 'flex-start',
              mt: '30px',
            },
          }}
          expandIcon={
            isBackupServer ? <div style={{ width: 24 }} /> : <ExpandMoreIcon />
          }
          tabIndex={-1}
        >
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
              }}
            >
              <Typography
                variant="body1"
                sx={{ mr: 1.5, whiteSpace: 'nowrap' }}
              >
                {server.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                  '.MuiTypography-root': { pr: 1 },
                }}
              >
                {isBackupServer && (
                  <ServerInfo
                    label="Backup For"
                    info={
                      servers.find((s) => s.id === server.backupFor?.id)?.name
                    }
                  />
                )}
                {!isBackupServer && (
                  <ServerInfo
                    label="Companies"
                    info={(server.companies?.length ?? 0).toString()}
                  />
                )}
                {!isBackupServer &&
                  server.packages &&
                  server.packages.length > 0 && (
                    <ServerInfo
                      label="Packages"
                      infoList={server.packages.map((p) => p.name)}
                    />
                  )}
                {server.localName && (
                  <ServerInfo label="Local Name" info={server.localName} />
                )}
                {server.lastSeen && (
                  <ServerInfo
                    label="Last Seen"
                    info={dateTimeFormat(server.lastSeen)}
                    tooltip={formatDistance(
                      new Date(server.lastSeen),
                      new Date(),
                      {
                        includeSeconds: false,
                        addSuffix: true,
                      }
                    )}
                  />
                )}
                {server.version && (
                  <ServerInfo
                    label="Version"
                    info={server.version.versionString}
                  />
                )}
              </Box>
              <Box
                sx={{
                  ml: 2,
                  mt: 1,
                  mr: 3,
                  display: 'flex',
                  flexDirection: 'row',
                  '>div': {
                    ml: 1,
                  },
                }}
              >
                {server.licenseKeysQty !== undefined &&
                  server.licenseKeysQty > 0 && (
                    <div>
                      <Tooltip title="Recent Licenses">
                        <IconButton
                          sx={{ lineHeight: 1 }}
                          color={keysExpanded ? 'success' : 'secondary'}
                          onClick={(e) => {
                            e.stopPropagation();
                            setKeysExpanded(!keysExpanded);
                          }}
                        >
                          <History />
                        </IconButton>
                      </Tooltip>
                    </div>
                  )}
                <div>
                  <Tooltip title="Edit Server">
                    <IconButton
                      tabIndex={-1}
                      disabled={disabled}
                      color={editMode ? 'success' : 'primary'}
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditMode(!editMode);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Delete Server">
                    <IconButton
                      tabIndex={-1}
                      disabled={disabled}
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        removeServer();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                {ownerId && (
                  <div>
                    <Tooltip title="Manually generate license">
                      <IconButton
                        component={NavLink}
                        to={`/admin/generate_license?ownerId=${ownerId}&serverId=${
                          server.id
                        }&machineId=${server.machineId ?? ''}`}
                        color="warning"
                      >
                        <Key />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: editMode ? 1 : 0 }}>
              <Accordion
                expanded={editMode}
                TransitionProps={{ unmountOnExit: true }}
                onClick={(e) => e.stopPropagation()}
                sx={{ cursor: 'default' }}
              >
                <span></span>
                <AccordionDetails
                  sx={{
                    mt: 1,
                    display: 'flex',
                    '.MuiFormControl-root': { mb: 0 },
                  }}
                >
                  <TextField
                    required
                    disabled={disabled}
                    label="Server Name"
                    variant="outlined"
                    value={server.name || ''}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      setState({ ...server, name: e.target.value })
                    }
                  />
                  <Tooltip
                    title={
                      servers.length === 0
                        ? 'Save to enable selecting the server to back up'
                        : null
                    }
                  >
                    <span>
                      <TextField
                        select
                        disabled={disabled || servers.length === 0}
                        value={server.backupFor?.id || 'none'}
                        label="Backup For"
                        onClick={(e) => e.stopPropagation()}
                        sx={{ minWidth: 200, ml: 1 }}
                        onChange={(e) =>
                          setState({
                            ...server,
                            backupFor:
                              e.target.value === 'none'
                                ? null
                                : { id: e.target.value },
                          })
                        }
                      >
                        <MenuItem value={'none'}>N/A</MenuItem>
                        {servers?.map((s) =>
                          s.id === server.id ? null : (
                            <MenuItem value={s.id} key={s.id}>
                              {s.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </span>
                  </Tooltip>
                  {!isBackupServer && (
                    <FormControl
                      disabled={!packages?.packages}
                      sx={{ minWidth: '150px', ml: 1, flexGrow: 1 }}
                    >
                      <InputLabel id={`server-packages-label-${server.id}`}>
                        Packages
                      </InputLabel>
                      <Select
                        labelId={`server-packages-label-${server.id}`}
                        label="Packages"
                        multiple
                        value={server.packageIds}
                        onChange={(e) => {
                          const v = e.target.value as string[];
                          const packageList = packages?.packages.filter((p) =>
                            v.includes(p.id)
                          );
                          setState({
                            ...server,
                            packageIds: v,
                            packages: packageList,
                          });
                        }}
                      >
                        {packages?.packages.map((p) => (
                          <MenuItem key={p.id} value={p.id}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </AccordionSummary>
        {!isBackupServer && (
          <AccordionDetails>
            <Divider sx={{ ml: -2, mr: -2, mb: 1, mt: -1 }} />
            {server.companies && server.companies.length > 0 && (
              <Typography variant="h6" sx={{ mb: 1 }}>
                Companies
              </Typography>
            )}
            {server.companies?.map((company, index) => (
              <CompanyEdit
                key={company.id || index}
                company={company}
                disabled={disabled}
                setState={(newC) =>
                  setState({
                    ...server,
                    companies: server.companies?.map((c, i) =>
                      i === index ? newC : c
                    ),
                  })
                }
                removeCompany={() =>
                  setState({
                    ...server,
                    companies: server.companies?.filter((_, i) => i !== index),
                  })
                }
              />
            ))}

            <Box
              sx={{
                ml: 2,
                mt: 2,
                mb: -2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                size="small"
                sx={{ ml: 2, mt: 2, width: 200 }}
                select
                label="Quantity to Add"
                value={addQty}
                onChange={(e) => setAddQty(parseFloat(e.target.value))}
              >
                {qtyCompanies < 3 && (
                  <MenuItem value={3 - qtyCompanies}>
                    {3 - qtyCompanies} (total will be 3)
                  </MenuItem>
                )}
                {qtyCompanies !== 2 && (
                  <MenuItem value={1}>
                    1 (total will be {qtyCompanies + 1})
                  </MenuItem>
                )}
                {qtyCompanies < 10 && (
                  <MenuItem value={10 - qtyCompanies}>
                    {10 - qtyCompanies} (total will be 10)
                  </MenuItem>
                )}
                <MenuItem value={10}>
                  10 (total will be {qtyCompanies + 10})
                </MenuItem>
              </TextField>{' '}
              <Button
                startIcon={<AddIcon />}
                sx={{ ml: 2 }}
                variant="outlined"
                onClick={handleAddCompanies}
              >
                Add {addQty} {addQty === 1 ? 'Company' : 'Companies'}
              </Button>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
      {server.licenseKeysQty !== undefined && server.licenseKeysQty > 0 && (
        <Accordion
          expanded={keysExpanded}
          TransitionProps={{ unmountOnExit: true }}
          sx={{ '&.Mui-expanded': { mt: 0 } }}
        >
          <span></span>
          <AccordionDetails sx={{ p: 0 }}>
            {loading && <LinearProgress />}
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Issued</TableCell>
                  <TableCell>Expiration</TableCell>
                  <TableCell>Version</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentLicenses?.licenseKeysRecent.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{dateTimeFormat(row.issued)}</TableCell>
                    <TableCell>{dateTimeFormat(row.expiration)}</TableCell>
                    <TableCell>{row.version.versionString}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default ServerEdit;

interface ServerInfoProps {
  label: string;
  info?: string;
  infoList?: string[];
  tooltip?: string;
}
const ServerInfo: FC<ServerInfoProps> = ({
  label,
  info,
  infoList,
  tooltip,
}) => {
  const result = (
    <Box pl={1} pt={0.5} flexGrow={1} flexBasis={1}>
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        {label}:
      </Typography>
      {info && <Typography variant="body2">{info}</Typography>}
      {infoList &&
        infoList.length > 0 &&
        infoList.map((i) => (
          <Chip label={i} size="small" sx={{ mr: 0.5, mt: 0.25 }} />
        ))}
    </Box>
  );
  return tooltip ? <Tooltip title={tooltip}>{result}</Tooltip> : result;
};
