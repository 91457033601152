import { useQuery } from '@apollo/client';
import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { client } from '../gql/ApolloClient';
import { authEvents } from './AuthEvents';
import { whoAmIQuery } from './queries';
import { WhoAmIQuery } from './types/queries';

interface State {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  user: WhoAmIQuery['whoAmI'];
  isLoggedIn: boolean;
}

export const UserContext = createContext<State>({
  loading: true,
  user: null,
  isLoggedIn: false,
  setLoading: () => undefined,
});

interface Props {
  children?: ReactNode;
}

export const UserContextProvider: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { data, refetch } = useQuery<WhoAmIQuery>(whoAmIQuery, {
    onCompleted: () => setLoading(false),
    onError: () => setLoading(false),
  });

  // I've tried all sorts of things to get the cache to clear but have had no success. The best we can do is re-query
  // so that the results are correctly updated. Sigh.
  useEffect(() => client.onClearStore(refetch), [refetch]);

  useEffect(
    () => authEvents.on('notAuthenticated', () => client.clearStore()),
    []
  );

  return (
    <UserContext.Provider
      value={{
        loading,
        setLoading,
        user: data?.whoAmI,
        isLoggedIn: !!data?.whoAmI,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
