import { useQuery } from '@apollo/client';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import GridWrapper from 'lib/GridWrapper';
import Page from 'components/Page';
import { featuresQuery } from './queries';
import { FeatureQueryVariables, FeaturesQuery } from './types/queries';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 400,
  },
  {
    field: 'firstVersion',
    headerName: 'First Version',
    width: 120,
    valueGetter: (params) => params.value?.versionString,
  },
  {
    field: 'lastVersion',
    headerName: 'Last Version',
    width: 120,
    valueGetter: (params) => params.value?.versionString,
  },
  {
    field: 'hasExpiration',
    headerName: 'Expires',
    width: 75,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ value }) => <Checkbox disabled checked={value} />,
  },
  {
    field: 'companiesQty',
    headerName: 'Qty Companies',
    filterable: false,
    width: 120,
  },
  {
    field: 'packagesQty',
    headerName: 'Qty Packages',
    filterable: false,
    width: 120,
  },
];

const FeaturesPage: FC = () => {
  const nav = useNavigate();
  const { data, loading } = useQuery<FeaturesQuery, FeatureQueryVariables>(
    featuresQuery
  );

  return (
    <Page
      options={
        <Tooltip title="New feature">
          <IconButton
            style={{
              color: 'white',
            }}
            onClick={() => nav('/admin/features/new')}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      }
      title="Features"
    >
      <GridWrapper
        columns={columns}
        loading={loading}
        basePath="/admin/features/"
        rows={data?.features || []}
      />
    </Page>
  );
};

export default FeaturesPage;
