import { createBrowserRouter } from 'react-router-dom';
import { Home } from '../components/Home';
import { Layout } from '../components/Layout';
import ConnectedClients from '../components/pages/ConnectedClients';
import FeaturesPage from '../components/pages/features';
import GenerateLicensePage from '../components/pages/GenerateLicense';
import NotFoundPage from '../components/pages/NotFound';
import OwnersPage from '../components/pages/owners';
import EditOwnerPage from '../components/pages/owners/Edit';
import EditFeaturePage from 'components/pages/features/Edit';
import PackagesPage from '../components/pages/Packages';
import PackageEditPage from '../components/pages/Packages/Edit';
import SigningKeysPage from '../components/pages/signingKeys';
import SigningKeysEditPage from 'components/pages/signingKeys/Edit';
import UsersPage from '../components/pages/users';
import EditUserPage from '../components/pages/users/Edit';
import SalesOfficesPage from 'components/pages/salesOffices';
import SalesOfficesEditPage from 'components/pages/salesOffices/Edit';
import LoginPage from 'components/pages/Login';
import ForgotPasswordPage from 'components/pages/ForgotPassword';
import ProfilePage from 'components/pages/Profile';
import VersionsPage from 'components/pages/Versions';
import VersionEditPage from 'components/pages/Versions/Edit';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <NotFoundPage />,
    children: [
      { id: 'home', index: true, element: <Home /> },
      {
        path: 'admin/owners',
        // element: <Outlet />,
        children: [
          { id: 'owners_index', index: true, element: <OwnersPage /> },
          { id: 'owner_edit', path: ':id', element: <EditOwnerPage /> },
        ],
      },
      {
        id: 'connected_clients',
        path: 'admin/connected_clients',
        element: <ConnectedClients />,
      },
      {
        path: 'admin/features',
        children: [
          { id: 'features_index', index: true, element: <FeaturesPage /> },
          { id: 'feature_edit', path: ':id', element: <EditFeaturePage /> },
        ],
      },
      {
        path: 'admin/packages',
        children: [
          { id: 'packages_index', index: true, element: <PackagesPage /> },
          { id: 'packages_edit', path: ':id', element: <PackageEditPage /> },
        ],
      },
      {
        path: 'admin/users',
        children: [
          { id: 'users_index', index: true, element: <UsersPage /> },
          { id: 'user_edit', path: ':id', element: <EditUserPage /> },
        ],
      },
      {
        path: 'admin/sales_offices',
        children: [
          {
            id: 'sales_offices_index',
            index: true,
            element: <SalesOfficesPage />,
          },
          {
            id: 'sales_office_edit',
            path: ':id',
            element: <SalesOfficesEditPage />,
          },
        ],
      },
      {
        path: 'admin/signing_keys',
        children: [
          {
            id: 'signing_keys_index',
            index: true,
            element: <SigningKeysPage />,
          },
          {
            id: 'signing_keys_edit',
            path: ':id',
            element: <SigningKeysEditPage />,
          },
        ],
      },
      {
        path: '/admin/versions',
        children: [
          {
            id: 'versions_index',
            index: true,
            element: <VersionsPage />,
          },
          {
            id: 'versions_edit',
            path: ':id',
            element: <VersionEditPage />,
          },
        ],
      },
      {
        path: 'admin/generate_license',
        id: 'generate_license',
        element: <GenerateLicensePage />,
      },
      {
        path: 'login',
        id: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgot_password',
        id: 'forgot_password',
        element: <ForgotPasswordPage />,
      },
      {
        path: 'profile',
        id: 'profile',
        element: <ProfilePage />,
      },
    ],
  },
]);
