import { gql } from '@apollo/client';

const signingKeyFragment = gql`
  fragment SigningKey on SigningKey {
    id
    name
    expiration
    created
    qtyLicenseKeys
    qtyVersions
  }
`;

export const signingKeysQuery = gql`
  query SigningKeys($where: SigningKeyFilterInput) {
    signingKeys(where: $where) {
      ...SigningKey
    }
  }
  ${signingKeyFragment}
`;

export const signingKeyQuery = gql`
  query SigningKey($id: UUID!) {
    signingKey(id: $id) {
      ...SigningKey
      publicKey
      recentLicenseKeys {
        id
        issued
        expiration
        server {
          id
          name
          owner {
            id
            name
          }
          version {
            id
            versionString
          }
        }
      }
      versions {
        id
        versionString
        qtyServers
      }
    }
  }
  ${signingKeyFragment}
`;

export const signingKeyAddMutation = gql`
  mutation SigningKeyAdd($name: String!) {
    signingKeyAdd(name: $name) {
      ...SigningKey
      publicKey
    }
  }
  ${signingKeyFragment}
`;

export const signingKeyUpdateMutation = gql`
  mutation SigningKeyUpdate($input: SigningKeyUpdateInput!) {
    signingKeyUpdate(input: $input) {
      ...SigningKey
      publicKey
    }
  }
  ${signingKeyFragment}
`;

export const signingKeyExpireMutation = gql`
  mutation SigningKeyExpire($id: UUID!) {
    signingKeyExpire(id: $id) {
      ...SigningKey
      publicKey
    }
  }
  ${signingKeyFragment}
`;

export const signingKeyRemoveMutation = gql`
  mutation SigningKeyRemove($id: UUID!) {
    signingKeyRemove(id: $id)
  }
`;
