import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { FC, FormEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginFunction } from 'lib/hooks/useLogIn';
import { QueryParams } from './index';

interface Props {
  logIn: LoginFunction;
  loading: boolean;
  queryParams?: QueryParams;
  rememberMe: boolean;
  setRememberMe: (state: boolean) => void;
}

const UsernamePasswordUi: FC<Props> = ({
  logIn,
  loading,
  queryParams,
  rememberMe,
  setRememberMe,
}) => {
  const [loginError, setLoginError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [usernameError, setUsernameError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!username) {
        setUsernameError('Username or email is required');
        return;
      }
      if (!password) {
        setPasswordError('Password is required');
        return;
      }

      const response = await logIn({
        usernameOrEmail: username,
        password: password,
        rememberMe: rememberMe,
      });

      if (response !== true) {
        setLoginError(response);
      }
    },
    [logIn, password, rememberMe, username]
  );

  const nav = useNavigate();

  return (
    <form onSubmit={onSubmit}>
      <Card sx={{ mt: 5, p: 3 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {loginError && (
            <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
              {loginError}
            </Alert>
          )}
          <TextField
            autoFocus
            label="Username or Email"
            sx={{ mb: 2 }}
            disabled={loading}
            helperText={usernameError}
            error={!!usernameError}
            value={username}
            onChange={(e) => {
              setUsernameError('');
              setUsername(e.target.value);
            }}
          />
          <TextField
            label="Password"
            type="password"
            sx={{ mb: 2 }}
            disabled={loading}
            helperText={passwordError}
            error={!!passwordError}
            value={password}
            onChange={(e) => {
              setPasswordError('');
              setPassword(e.target.value);
            }}
          />
          <FormControlLabel
            disabled={loading}
            label="Remember Me"
            control={
              <Checkbox
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            }
          />
        </CardContent>
        <CardActions>
          <LoadingButton type="submit" loading={loading} variant="contained">
            Log in
          </LoadingButton>
          <Button
            sx={{ ml: 2 }}
            variant="text"
            onClick={() =>
              nav(
                `/forgot_password?returnUrl=${encodeURIComponent(
                  queryParams?.returnUrl ?? ''
                )}`
              )
            }
          >
            Forgot Password
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default UsernamePasswordUi;
