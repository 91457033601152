import { gql, useSubscription } from '@apollo/client';
import { Key, Storefront, Widgets } from '@mui/icons-material';
import DevicesIcon from '@mui/icons-material/Devices';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import ListIcon from '@mui/icons-material/List';
import {
  Box,
  Divider,
  Drawer,
  ListItemButton,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  Chip,
} from '@mui/material';
import { FC, useContext } from 'react';
import { NavLink as DomLink, Outlet } from 'react-router-dom';
import { UserContext } from '../lib/UserContext';
import Book from '../galaxy.jpg';
import LoginMenu from './LoginMenu';
import { QtyConnectedClientsSubscription } from './types/Layout';

const qtyConnectedClients = gql`
  subscription QtyConnectedClients {
    clientConnections {
      qtyConnections
    }
  }
`;

interface LayoutProps {}

export const drawerWidth = 255;
const navItemStyle = {
  padding: '10px',
};

export const Layout: FC<LayoutProps> = () => {
  const { user: userData, isLoggedIn } = useContext(UserContext);
  const { data } = useSubscription<QtyConnectedClientsSubscription>(
    qtyConnectedClients,
    { skip: !isLoggedIn }
  );

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Drawer
        anchor="left"
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            display: 'flex',
          },
        }}
      >
        <List component="nav" style={{ padding: '0' }}>
          <div
            style={{
              position: 'relative',
              height: '150px',
            }}
          >
            <img
              src={Book}
              alt=""
              style={{
                height: '100%',
                width: '100%',
              }}
            />
            <div
              style={{
                position: 'absolute',
                width: '100%',
                bottom: '0',
                padding: '15px',
              }}
            >
              <Typography style={{ color: 'white' }} variant="h5">
                Galaxy
              </Typography>
              <Typography style={{ color: 'white' }} variant="subtitle1">
                by ABC Software
              </Typography>
            </div>
          </div>
          {(userData?.isAdmin || userData?.isTech) && (
            <>
              <ListItemButton
                component={DomLink}
                to="/admin/owners"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary="Owners" />
              </ListItemButton>
              <ListItemButton
                component={DomLink}
                to="/admin/connected_clients"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <DevicesIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <>
                      Connected Clients
                      <Chip
                        sx={{ ml: 1 }}
                        color={
                          data?.clientConnections.qtyConnections
                            ? 'info'
                            : 'default'
                        }
                        size="small"
                        label={data?.clientConnections.qtyConnections ?? '?'}
                      />
                    </>
                  }
                />
              </ListItemButton>
              <ListItemButton
                style={navItemStyle}
                component={DomLink}
                to="/admin/generate_license"
              >
                <ListItemIcon>
                  <Key />
                </ListItemIcon>
                <ListItemText primary="Generate License" />
              </ListItemButton>
            </>
          )}
          {userData?.isAdmin && (
            <>
              <ListItemButton
                component={DomLink}
                to="/admin/versions"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <ListIcon />
                </ListItemIcon>
                <ListItemText primary="Versions" />
              </ListItemButton>

              <ListItemButton
                component={DomLink}
                to="/admin/signing_keys"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText primary="Signing Keys" />
              </ListItemButton>
              <ListItemButton
                component={DomLink}
                to="/admin/features"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <LibraryBooksIcon />
                </ListItemIcon>
                <ListItemText primary="Features" />
              </ListItemButton>
              <ListItemButton
                component={DomLink}
                to="/admin/packages"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <Widgets />
                </ListItemIcon>
                <ListItemText primary="Packages" />
              </ListItemButton>
              <ListItemButton
                component={DomLink}
                to="/admin/users"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
              <ListItemButton
                component={DomLink}
                to="/admin/sales_offices"
                style={navItemStyle}
              >
                <ListItemIcon>
                  <Storefront />
                </ListItemIcon>
                <ListItemText primary="Sales Offices" />
              </ListItemButton>
            </>
          )}
        </List>
        <Divider sx={{ flexGrow: 1 }} />
        <List>
          <LoginMenu />
        </List>
      </Drawer>
      <Outlet />
    </Box>
  );
};
