import { useMutation, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditPageCard from '../../../lib/components/EditPageCard';
import { extractError } from '../../../lib/gql/extractError';
import Page from '../../Page';
import {
  salesOfficesQuery,
  userAddMutation,
  userQuery,
  usersQuery,
  usersRemoveMutation,
  userUpdateMutation,
} from './queries';
import {
  SalesOfficeListQuery,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserAddMutation,
  UserAddMutationVariables,
  UserFragment,
  UserQuery,
  UserQueryVariables,
  UsersRemoveMutation,
  UsersRemoveMutationVariables,
} from './types/queries';
import YubiKeysTable from './YubiKeysTable';

const EditUserPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<{ id: string }>();
  const isNew = params.id === 'new';

  const [state, setState] = useState<
    Partial<
      Omit<UserFragment, 'salesOffices' | '__typename'> & {
        salesOffices: string[];
        password: string;
      }
    >
  >({});

  const [error, setError] = useState('');

  const { loading, data } = useQuery<UserQuery, UserQueryVariables>(userQuery, {
    variables: {
      id: params.id!,
    },
    skip: isNew,
    onCompleted: (data) =>
      setState({
        ...data.user,
        salesOffices: data.user?.salesOffices.map((so) => so.id),
      }),
  });
  const { data: salesOffices, loading: officesLoading } =
    useQuery<SalesOfficeListQuery>(salesOfficesQuery);

  const [update, { loading: updateLoading }] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(userUpdateMutation, {
    refetchQueries: [{ query: usersQuery }],
    awaitRefetchQueries: true,
    onError: (e) => {
      setError(extractError(e));
    },
  });
  const [add, { loading: addLoading }] = useMutation<
    UserAddMutation,
    UserAddMutationVariables
  >(userAddMutation, {
    refetchQueries: [{ query: usersQuery }],
    awaitRefetchQueries: true,
    onError: (e) => {
      setError(extractError(e));
    },
  });

  const [remove, { loading: removeLoading }] = useMutation<
    UsersRemoveMutation,
    UsersRemoveMutationVariables
  >(usersRemoveMutation, {
    refetchQueries: [{ query: usersQuery }],
    awaitRefetchQueries: true,
  });

  const isSaving = loading || updateLoading || addLoading || removeLoading;
  const hasYubiKeys = !!data?.user?.yubiKeys && data.user.yubiKeys.length > 0;
  return (
    <Page
      title="Edit User"
      backButton="/admin/users"
      options={
        !isNew && (
          <Tooltip title="Delete User">
            <span>
              <IconButton
                color="error"
                disabled={isSaving}
                onClick={async () => {
                  await remove({ variables: { id: state.id } });
                  nav('/admin/users');
                }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <EditPageCard
          fullWidth={hasYubiKeys}
          title={isNew ? state.userName || 'New User' : data?.user?.userName}
          error={error}
          actions={
            <Button
              variant="contained"
              disabled={isSaving}
              onClick={async () => {
                setError('');
                if (isNew) {
                  if (!state.password) {
                    setError(
                      'The password is required when creating a new user'
                    );
                    return;
                  }
                  const res = await add({
                    variables: {
                      input: {
                        name: state.userName || '',
                        email: state.email || '',
                        password: state.password,
                        isAdmin: !!state.isAdmin,
                        isTech: !!state.isTech,
                        salesOfficeIds: state.salesOffices || [],
                      },
                    },
                  });
                  if (res.errors) return;
                } else {
                  const res = await update({
                    variables: {
                      input: {
                        id: state.id,
                        name: state.userName || '',
                        password: state.password,
                        isAdmin: !!state.isAdmin,
                        isTech: !!state.isTech,
                        email: state.email || '',
                        salesOfficeIds: state.salesOffices || [],
                      },
                    },
                  });
                  if (res.errors) return;
                }
                nav('/admin/users');
              }}
            >
              Save
            </Button>
          }
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={hasYubiKeys ? 4 : 12}
              xl={hasYubiKeys ? 3 : 12}
            >
              <div>
                <TextField
                  sx={{ flexGrow: 0 }}
                  value={state.userName || ''}
                  label="Username"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, userName: e.target.value })
                  }
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  value={state.email || ''}
                  label="Email"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  variant="outlined"
                />
              </div>
              <div>
                <TextField
                  sx={{ width: 200 }}
                  type="password"
                  value={state.password || ''}
                  label="New Password"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, password: e.target.value })
                  }
                  variant="outlined"
                  helperText={
                    !isNew &&
                    "This will immediately overwrite the user's current password."
                  }
                />
              </div>
              <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                  disabled={isSaving}
                  label="Admin"
                  control={
                    <Checkbox
                      checked={!!state.isAdmin}
                      onChange={(e) =>
                        setState({ ...state, isAdmin: e.target.checked })
                      }
                    />
                  }
                />
                <FormControlLabel
                  disabled={isSaving}
                  label="Tech"
                  control={
                    <Checkbox
                      checked={!!state.isTech}
                      onChange={(e) =>
                        setState({ ...state, isTech: e.target.checked })
                      }
                    />
                  }
                />
              </Box>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Sales Offices
              </Typography>
              {officesLoading ? (
                <LinearProgress />
              ) : (
                salesOffices?.salesOffices.map((so) => (
                  <FormControlLabel
                    key={so.id}
                    disabled={isSaving}
                    control={
                      <Checkbox
                        checked={!!state.salesOffices?.includes(so.id)}
                        onChange={(e) =>
                          e.target.checked
                            ? setState({
                                ...state,
                                salesOffices: [
                                  ...(state.salesOffices || []),
                                  so.id,
                                ],
                              })
                            : setState({
                                ...state,
                                salesOffices:
                                  state.salesOffices?.filter(
                                    (id) => id !== so.id
                                  ) || [],
                              })
                        }
                      />
                    }
                    label={so.name}
                  />
                ))
              )}
            </Grid>
            {hasYubiKeys && (
              <Grid item xs={12} lg={8} xl={6}>
                <YubiKeysTable
                  userId={data!.user!.id}
                  keys={data!.user!.yubiKeys}
                />
              </Grid>
            )}
          </Grid>
        </EditPageCard>
      )}
    </Page>
  );
};

export default EditUserPage;
