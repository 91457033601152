import { Box } from '@mui/material';
import { FC } from 'react';
import { FeatureFragment } from '../../features/types/queries';
import { StateCompanyFeaturesType } from './FeatureEdit';

interface FeatureInfoProps {
  feature: FeatureFragment;
  companyFeature: StateCompanyFeaturesType;
}

export const FeatureInfo: FC<FeatureInfoProps> = ({
  feature,
  companyFeature,
}) => {
  return (
    <span>
      {' '}
      &#8226; {feature.name}{' '}
      {feature.hasExpiration && (
        <Box sx={{ display: 'inline-block' }}>
          ({companyFeature.expiration?.substring(0, 10)})
        </Box>
      )}
    </span>
  );
};
