import { ApolloError, gql, useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { cache } from '../gql/ApolloClient';
import { extractError } from '../gql/extractError';
import { whoAmIFragment, whoAmIQuery } from '../UserContext/queries';
import { WhoAmIQuery } from '../UserContext/types/queries';
import {
  LogInMutation,
  LogInMutationVariables,
  YubiOtpMutation,
  YubiOtpMutationVariables,
} from './types/useLogIn';

const loginMutation = gql`
  mutation LogIn($input: SignInInput!) {
    signIn(input: $input) {
      success
      requiresMfa
      user {
        ...WhoAmI
      }
    }
  }
  ${whoAmIFragment}
`;

const yubiKeyOtpMutation = gql`
  mutation YubiOtp($input: SignInYubiKeyOtpInput!) {
    signInVerifyYubiKeyOtp(input: $input) {
      success
      user {
        ...WhoAmI
      }
    }
  }
  ${whoAmIFragment}
`;

export enum LoginPhases {
  UsernamePassword,
  MFA,
}

export type LoginFunction = (
  credentials: LogInMutationVariables['input']
) => Promise<string | true>;
export type SubmitYubiKeyOtpFunction = (
  otp: YubiOtpMutationVariables['input']
) => Promise<string | true>;
export type SetPhaseFunction = (phase: LoginPhases) => void;
interface Return {
  phase: LoginPhases;
  setPhase: SetPhaseFunction;
  loading: boolean;
  logIn: LoginFunction;
  submitYubiKeyOtp: SubmitYubiKeyOtpFunction;
}

export const useLogIn = (): Return => {
  const [phase, setPhase] = useState<LoginPhases>(LoginPhases.UsernamePassword);
  const [doLogIn, { loading }] = useMutation<
    LogInMutation,
    LogInMutationVariables
  >(loginMutation);

  const [doYubiOtp, { loading: otpLoading }] = useMutation<
    YubiOtpMutation,
    YubiOtpMutationVariables
  >(yubiKeyOtpMutation);

  const logIn = useCallback(
    async (credentials: LogInMutationVariables['input']) => {
      try {
        const response = await doLogIn({
          variables: {
            input: credentials,
          },
        });

        if (response.data?.signIn.success) {
          // successful sign in.
          cache.writeQuery<WhoAmIQuery>({
            data: {
              whoAmI: response.data.signIn.user,
            },
            query: whoAmIQuery,
          });
        }
        if (response.data?.signIn.requiresMfa) {
          setPhase(LoginPhases.MFA);
        }
      } catch (e) {
        return extractError(e as ApolloError);
      }

      return true;
    },
    [doLogIn]
  );

  const submitYubiKeyOtp = useCallback<SubmitYubiKeyOtpFunction>(
    async (otp) => {
      try {
        const response = await doYubiOtp({ variables: { input: otp } });
        if (response.data?.signInVerifyYubiKeyOtp.success) {
          cache.writeQuery<WhoAmIQuery>({
            data: {
              whoAmI: response.data.signInVerifyYubiKeyOtp.user,
            },
            query: whoAmIQuery,
          });
        }
      } catch (e) {
        return extractError(e as ApolloError);
      }

      return true;
    },
    [doYubiOtp]
  );

  return {
    loading: loading || otpLoading,
    logIn,
    phase,
    setPhase,
    submitYubiKeyOtp,
  };
};
