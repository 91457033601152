import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
} from '@mui/material';
import { FC, useContext } from 'react';
import { UserContext } from 'lib/UserContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { useParseQueryString } from 'lib/hooks/useParseQueryString';
import Page from '../../Page';

const ForgotPassword: FC = () => {
  const { isLoggedIn } = useContext(UserContext);

  const nav = useNavigate();
  const params = useParseQueryString<{ returnUrl?: string }>();

  if (isLoggedIn) {
    if (params.returnUrl) {
      return <Navigate to={params.returnUrl} replace />;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return (
    <Page title="Forgot Password" requireAuth={false}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <form>
          <Card sx={{ mt: 5, p: 3 }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
              <Alert severity="info" sx={{ mb: 2 }}>
                Sorry, you aren't allowed to forget your password yet.
              </Alert>
            </CardContent>
            <CardActions>
              <Button
                sx={{ ml: 2 }}
                variant="text"
                onClick={() =>
                  nav(
                    `/login?returnUrl=${encodeURIComponent(
                      params.returnUrl ?? ''
                    )}`
                  )
                }
              >
                Login
              </Button>
            </CardActions>
          </Card>
        </form>
      </Box>
    </Page>
  );
};

export default ForgotPassword;
