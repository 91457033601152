import { gql, useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { client } from '../gql/ApolloClient';
import { UserContext } from '../UserContext';
import { LogOutMutation, LogOutMutationVariables } from './types/useLogout';

const logOutMutation = gql`
  mutation LogOut {
    signOut
  }
`;

export const useLogout = (): {
  loading: boolean;
  logOut: () => Promise<void>;
} => {
  const location = useLocation();
  const { setLoading } = useContext(UserContext);
  const nav = useNavigate();
  const [doLogOut, { loading }] = useMutation<
    LogOutMutation,
    LogOutMutationVariables
  >(logOutMutation);

  const logOut = useCallback(async () => {
    setLoading(true);
    await doLogOut();
    await client.clearStore();
    setLoading(false);
    nav('/login?returnUrl=' + encodeURIComponent(location.pathname));
  }, [doLogOut, location.pathname, nav, setLoading]);

  return { logOut, loading };
};
