import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from '@mui/material';
import {
  LoginPhases,
  SetPhaseFunction,
  SubmitYubiKeyOtpFunction,
} from 'lib/hooks/useLogIn';
import { FC, useEffect, useRef, useState } from 'react';

interface Props {
  submitYubiKeyOtp: SubmitYubiKeyOtpFunction;
  loading: boolean;
  setPhase: SetPhaseFunction;
  rememberMe: boolean;
  isPhaseMfa: boolean;
}

const TwoFactorTokenUi: FC<Props> = ({
  submitYubiKeyOtp,
  loading,
  setPhase,
  rememberMe,
  isPhaseMfa,
}) => {
  const [otp, setOtp] = useState('');
  const [rememberClient, setRememberClient] = useState(false);
  const [loginError, setLoginError] = useState('');

  const yubiInputRef = useRef<HTMLInputElement>();
  useEffect(() => {
    if (isPhaseMfa) {
      // A slightly hacky way to set the focus on the yubi key input.
      setTimeout(() => yubiInputRef.current?.focus(), 50);
    }
  }, [isPhaseMfa]);

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        if (!otp) return;
        const response = await submitYubiKeyOtp({
          otp,
          rememberClient,
          rememberMe,
        });
        if (response !== true) {
          setLoginError(response);
        }
      }}
    >
      <Card sx={{ mt: 5, p: 3 }}>
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          {loginError && (
            <Alert variant="outlined" severity="error" sx={{ mb: 2 }}>
              {loginError}
            </Alert>
          )}
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberClient}
                  onChange={(e) => setRememberClient(e.target.checked)}
                />
              }
              label="Trust this device."
            />

            <FormHelperText sx={{ mt: -0.5 }}>
              Don't require 2-factor authentication next time.
            </FormHelperText>
          </FormControl>
          <TextField
            inputRef={yubiInputRef}
            label="Touch your YubiKey"
            disabled={loading}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            InputProps={{
              endAdornment: <img src="/yubico_logo.png" alt="Yubico Logo" />,
              autoComplete: 'off',
            }}
            onClick={(e) => (e.target as HTMLInputElement).select()}
          />
        </CardContent>
        <CardActions>
          <Button
            onClick={() => setPhase(LoginPhases.UsernamePassword)}
            variant="outlined"
          >
            Back
          </Button>
        </CardActions>
      </Card>
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default TwoFactorTokenUi;
