import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useParseQueryString = function <Args extends object>(): Args {
  const location = useLocation();

  return useMemo(() => {
    const url = new URLSearchParams(location.search);
    const result: { [key: string]: string } = {};

    for (const [key, value] of url.entries()) {
      result[key as string] = value as string;
    }

    return result as Args;
  }, [location.search]);
};
