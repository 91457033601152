import { Box, Checkbox, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { formatISO } from 'date-fns';
import { FC } from 'react';
import { PartialFragment } from 'lib/gql/typeHelpers';
import { FeatureFragment } from '../../features/types/queries';
import { CompanyFeatureFragment } from '../types/queries';

export type StateCompanyFeaturesType = PartialFragment<CompanyFeatureFragment>;

interface FeatureEditProps {
  feature: FeatureFragment;
  companyFeature?: StateCompanyFeaturesType;
  setState: (companyFeature: StateCompanyFeaturesType) => void;
  setActive: (active: boolean) => void;
  disabled: boolean;
}

export const FeatureEdit: FC<FeatureEditProps> = ({
  companyFeature,
  feature,
  setState,
  setActive,
  disabled,
}) => {
  const isActive = !!companyFeature;
  return (
    <Box
      sx={{
        pl: 3,
        pr: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography variant="body1">{feature?.name}</Typography>
      {feature.hasExpiration && (
        <DatePicker
          showDaysOutsideCurrentMonth
          label="Expiration Date"
          disabled={!isActive || disabled}
          value={companyFeature?.expiration || null}
          onChange={(newValue) => {
            setState({
              featureId: feature.id,
              expiration: formatISO(newValue ?? new Date()),
            });
          }}
          renderInput={(params) => (
            <TextField {...params} style={{ width: '200px' }} />
          )}
        />
      )}
      <div>
        <Checkbox
          disabled={disabled}
          checked={isActive}
          onChange={(value) => setActive(value.target.checked)}
        />
      </div>
    </Box>
  );
};
