import { gql } from '@apollo/client';

const connectedClientFragment = gql`
  fragment ConnectedClientCompany on ConnectedClientCompany {
    name
    localId
  }
  fragment ConnectedClient on ConnectedClient {
    id
    machineId
    version
    name
    ipAddress
    connectionTime
    companies {
      ...ConnectedClientCompany
    }
    server {
      id
      name
      owner {
        id
        name
      }
    }
  }
`;

export const connectedClientsQuery = gql`
  query ConnectedClients {
    clients: connectedClients {
      ...ConnectedClient
    }
  }
  ${connectedClientFragment}
`;

export const clientConnectionSubscription = gql`
  subscription ClientConnections {
    clientConnections {
      qtyConnections
      event {
        client {
          ...ConnectedClient
        }
        eventType
      }
    }
  }
  ${connectedClientFragment}
`;

export const assignMachineMutation = gql`
  mutation ServerAssign($machineId: String!, $serverId: UUID!) {
    serverAssignMachine(input: { machineId: $machineId, serverId: $serverId }) {
      id
      name
    }
  }
`;
