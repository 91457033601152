import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props<TData> {
  columns: GridColDef[];
  rows: TData[];
  loading: boolean;
  basePath: string | false;
  onSelectionChange?: (selectedIds: string[]) => void;
}

const GridWrapper: FC<Props<{}>> = ({
  columns,
  rows,
  loading,
  basePath,
  onSelectionChange,
}) => {
  const navigate = useNavigate();
  return (
    <DataGrid
      sx={{
        maxHeight: '100%',
        width: '100%',
      }}
      loading={loading}
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      className="clickRow"
      onRowClick={(params) =>
        basePath !== false ? navigate(basePath + params.row.id) : false
      }
      checkboxSelection={!!onSelectionChange}
      onSelectionModelChange={(selection) =>
        onSelectionChange &&
        onSelectionChange(selection.map((e) => e.toString()))
      }
      disableColumnFilter
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
};

export default GridWrapper;
