import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import EditPageCard from 'lib/components/EditPageCard';
import { extractError } from 'lib/gql/extractError';
import { PartialFragment } from 'lib/gql/typeHelpers';
import Page from '../../Page';
import YubiKeysTable from '../users/YubiKeysTable';
import {
  addYubiKeyMutation,
  profileQuery,
  profileUpdateMutation,
} from './queries';
import {
  ProfileFragment,
  ProfileQuery,
  ProfileQueryVariables,
  ProfileUpdateMutation,
  ProfileUpdateMutationVariables,
  UserYubiKeyAddMutation,
  UserYubiKeyAddMutationVariables,
} from './types/queries';

const ProfilePage: FC = () => {
  const [state, setState] = useState<
    PartialFragment<ProfileFragment, 'yubiKeys'> & {
      existingPassword?: string;
      newPassword?: string;
      newPasswordDup?: string;
    }
  >({});
  const [error, setError] = useState('');
  const [yubiOtp, setYubiOtp] =
    useState<Omit<UserYubiKeyAddMutationVariables['input'], 'userId'>>();

  const { data, loading } = useQuery<ProfileQuery, ProfileQueryVariables>(
    profileQuery,
    {
      onCompleted: (data) => {
        setState({
          ...data.whoAmI,
        });
      },
    }
  );

  const [update, { loading: updateLoading }] = useMutation<
    ProfileUpdateMutation,
    ProfileUpdateMutationVariables
  >(profileUpdateMutation, {
    onCompleted: (data) => {
      setState({ ...data.profileUpdate });
    },
    onError: (e) => {
      setError(extractError(e));
    },
  });

  const [addYubiOtp, { loading: addYubiOtpAdding }] = useMutation<
    UserYubiKeyAddMutation,
    UserYubiKeyAddMutationVariables
  >(addYubiKeyMutation);

  const isSaving = loading || updateLoading || addYubiOtpAdding;
  return (
    <Page title="Profile" backButton={true}>
      {loading ? (
        <LinearProgress />
      ) : (
        <EditPageCard
          fullWidth={true}
          error={error}
          actions={
            <Button
              variant="contained"
              disabled={isSaving}
              onClick={async () => {
                setError('');

                if (state.newPassword) {
                  if (state.newPassword !== state.newPasswordDup) {
                    setError('The passwords do not match');
                    return;
                  }
                  if (!state.existingPassword) {
                    setError(
                      'Your current password is required when setting a\nnew password'
                    );
                    return;
                  }
                }

                const res = await update({
                  variables: {
                    input: {
                      name: state.userName || '',
                      email: state.email || '',
                      newPassword: state.newPassword,
                      existingPassword: state.existingPassword,
                      requireTwoFactor: state.twoFactorEnabled,
                    },
                  },
                });
                if (res.errors) return;
                setState({
                  ...state,
                  existingPassword: '',
                  newPassword: '',
                  newPasswordDup: '',
                });
              }}
            >
              Save
            </Button>
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4} xl={3}>
              <div>
                <TextField
                  sx={{ flexGrow: 0 }}
                  value={state.userName || ''}
                  label="Username"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, userName: e.target.value })
                  }
                  variant="outlined"
                  required
                />
              </div>
              <div>
                <TextField
                  value={state.email || ''}
                  label="Email"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  variant="outlined"
                  required
                />
              </div>
              <div>
                <TextField
                  type="password"
                  value={state.newPassword || ''}
                  label="New Password"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, newPassword: e.target.value })
                  }
                  variant="outlined"
                />
              </div>

              <Collapse in={!!state.newPassword}>
                <TextField
                  type="password"
                  value={state.newPasswordDup || ''}
                  label="New Password Again"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, newPasswordDup: e.target.value })
                  }
                  variant="outlined"
                  required
                  sx={{ mr: 2 }}
                />
                <TextField
                  type="password"
                  value={state.existingPassword || ''}
                  label="Current Password"
                  disabled={isSaving}
                  onChange={(e) =>
                    setState({ ...state, existingPassword: e.target.value })
                  }
                  variant="outlined"
                  required
                />
              </Collapse>

              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                endIcon={<img src="/yubico_logo.png" alt="yubico logo" />}
                onClick={() => setYubiOtp({ otp: '' })}
              >
                Add YubiKey
              </Button>
              <Collapse in={yubiOtp !== undefined}>
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (!yubiOtp || !yubiOtp.otp) return;
                    await addYubiOtp({
                      variables: {
                        input: {
                          userId: data!.whoAmI!.id,
                          ...yubiOtp,
                        },
                      },
                      onError: (e) => setError(extractError(e)),
                    });
                    setYubiOtp(undefined);
                  }}
                >
                  <Box>
                    <TextField
                      value={yubiOtp?.name || ''}
                      onChange={(e) =>
                        setYubiOtp({ ...yubiOtp!, name: e.target.value })
                      }
                      label="Key Name"
                      sx={{ mb: 2, mt: 2 }}
                      disabled={isSaving}
                    />
                  </Box>
                  <Box>
                    <TextField
                      value={yubiOtp?.otp || ''}
                      onChange={(e) => {
                        setYubiOtp({ ...yubiOtp, otp: e.target.value });
                        setError('');
                      }}
                      label="Touch your YubiKey"
                      disabled={isSaving}
                    />
                    <Button
                      sx={{ ml: 2, mt: 2 }}
                      variant="outlined"
                      onClick={() => setYubiOtp(undefined)}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <button type="submit" style={{ display: 'none' }}></button>
                </form>
              </Collapse>
              {data?.whoAmI?.yubiKeys && data.whoAmI.yubiKeys.length > 0 && (
                <>
                  <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                      disabled={isSaving}
                      control={
                        <Checkbox
                          checked={state.twoFactorEnabled || false}
                          onChange={(e) =>
                            setState({
                              ...state,
                              twoFactorEnabled: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Require 2-Factor Authentication"
                    />
                  </Box>
                </>
              )}
            </Grid>
            {data?.whoAmI?.yubiKeys && data.whoAmI.yubiKeys.length > 0 && (
              <Grid item xs={12} lg={8} xl={6} sx={{ height: 500 }}>
                <YubiKeysTable
                  userId={data.whoAmI.id}
                  keys={data.whoAmI.yubiKeys}
                />
              </Grid>
            )}
          </Grid>
        </EditPageCard>
      )}
    </Page>
  );
};

export default ProfilePage;
