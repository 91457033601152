import {
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  SxProps,
} from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  title?: string;
  children?: ReactNode;
  error?: string;
  actions?: ReactNode;
  fullWidth?: boolean;
  sx?: SxProps;
}

const EditPageCard: FC<Props> = ({
  children,
  title,
  error,
  actions,
  sx,
  fullWidth = false,
}) => {
  return (
    <Card sx={{ width: '100%', maxWidth: fullWidth ? null : '500px', ...sx }}>
      {title && (
        <>
          <CardHeader title={title} />
          <Divider />
        </>
      )}
      <CardContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2, whiteSpace: 'pre' }}>
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: fullWidth ? null : 'fit-content',
            '.MuiFormControl-root': { mb: 2 },
          }}
        >
          {children}
        </Box>
      </CardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
};

export default EditPageCard;
