import { useMutation, useQuery } from '@apollo/client';
import { Save } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import EditPageCard from 'lib/components/EditPageCard';
import { extractError } from 'lib/gql/extractError';
import { PartialFragment } from 'lib/gql/typeHelpers';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from 'components/Page';
import { salesOfficesQuery } from '../../users/queries';
import { SalesOfficeListQuery } from '../../users/types/queries';
import {
  ownerAddMutation,
  ownerQuery,
  ownerRemoveMutation,
  ownersQuery,
  ownerUpdateMutation,
} from '../queries';
import {
  OwnerAddMutation,
  OwnerAddMutationVariables,
  OwnerDetailsFragment,
  OwnerQuery,
  OwnerQueryVariables,
  OwnerRemoveMutation,
  OwnerRemoveMutationVariables,
  OwnerUpdateMutation,
  OwnerUpdateMutationVariables,
} from '../types/queries';
import ServerEdit, { StateServerType } from './ServerEdit';

type StateType = PartialFragment<
  OwnerDetailsFragment,
  'servers' | 'serversQty' | 'companiesQty' | 'usersQty'
> & {
  servers?: StateServerType[];
};

const EditOwnerPage: FC = () => {
  const nav = useNavigate();
  const params = useParams<{ id: string }>();
  const isNew = params.id === 'new';

  const [state, setState] = useState<StateType>(
    !isNew
      ? {}
      : {
          servers: [
            {
              name: 'Server 1',
              companies: [
                { name: 'Company 1', allocatedUsers: 1, companyFeatures: [] },
              ],
              packageIds: [],
            },
          ],
        }
  );
  const [error, setError] = useState('');

  const { loading, data } = useQuery<OwnerQuery, OwnerQueryVariables>(
    ownerQuery,
    {
      variables: {
        id: params.id,
      },
      skip: isNew,
      onCompleted: (data) => {
        const owner = data.owner;
        if (!owner) return;
        setState({
          ...owner,
          servers: owner.servers.map((s) => ({
            ...s,
            packageIds: s.packages.map((p) => p.id),
          })),
        });
      },
    }
  );

  const { data: soData, loading: soLoading } =
    useQuery<SalesOfficeListQuery>(salesOfficesQuery);

  const [update, { loading: updating }] = useMutation<
    OwnerUpdateMutation,
    OwnerUpdateMutationVariables
  >(ownerUpdateMutation, {
    refetchQueries: [{ query: ownersQuery }],
    awaitRefetchQueries: true,
    onError: (e) => {
      setError(extractError(e));
    },
  });

  const [add, { loading: adding }] = useMutation<
    OwnerAddMutation,
    OwnerAddMutationVariables
  >(ownerAddMutation, {
    refetchQueries: [{ query: ownersQuery }],
    awaitRefetchQueries: true,
    onError: (e) => {
      setError(extractError(e));
    },
  });

  const handleSave: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      if (!state.name) {
        setError('The name field is required');
        return;
      }
      if (!state.salesOfficeId) {
        setError('The Sales Office is required');
        return;
      }
      setError('');

      const saveAndContinue = e.currentTarget.name === 'save_continue';

      if (isNew) {
        const result = await add({
          variables: {
            input: {
              name: state.name,
              email: state.email,
              salesOfficeId: state.salesOfficeId,
              servers:
                state.servers?.map((s, serverI) => ({
                  name: s.name ?? `Server ${serverI + 1}`,
                  packageIds: s.packageIds,
                  companies: s.companies!.map((c, companyI) => ({
                    name: c.name ?? `Company ${companyI + 1}`,
                    allocatedUsers: c.allocatedUsers || 0,
                    features: !c.companyFeatures
                      ? []
                      : c.companyFeatures.map((cf) => ({
                          featureId: cf.featureId,
                          expiration: cf.expiration,
                        })),
                  })),
                })) ?? [],
            },
          },
        });
        if (result.data?.ownerAdd.id && !saveAndContinue) {
          // Only forward if we saved successfully and have an id.
          nav('/admin/owners', { replace: true });
        }
      } else {
        const result = await update({
          variables: {
            input: {
              id: state.id,
              name: state.name,
              email: state.email,
              salesOfficeId: state.salesOfficeId,
              servers:
                state.servers?.map((s, serverI) => ({
                  id: s.id,
                  name: s.name ?? `Server ${serverI + 1}`,
                  backupForId: s.backupFor?.id,
                  packageIds: s.packageIds,
                  companies:
                    s.companies?.map((c, companyI) => ({
                      id: c.id,
                      name: c.name ?? `Company ${companyI + 1}`,
                      allocatedUsers: c.allocatedUsers || 0,
                      features: !c.companyFeatures
                        ? []
                        : c.companyFeatures.map((cf) => ({
                            featureId: cf.featureId,
                            expiration: cf.expiration,
                          })),
                    })) ?? [],
                })) ?? [],
            },
          },
        });
        if (!result.errors && !saveAndContinue) {
          nav('/admin/owners');
        }
      }
    },
    [add, update, nav, isNew, state]
  );

  const [remove, { loading: removing }] = useMutation<
    OwnerRemoveMutation,
    OwnerRemoveMutationVariables
  >(ownerRemoveMutation, {
    refetchQueries: [{ query: ownersQuery }],
    awaitRefetchQueries: true,
  });
  const [deleting, setDeleting] = useState(false);

  const isChanging = updating || adding || removing;

  const qtyCompanies = useMemo(
    () =>
      state.servers?.reduce((prev, cur) => {
        return prev + (cur.companies?.length ?? 0);
      }, 0) ?? 0,
    [state.servers]
  );
  const serversList = useMemo(
    () => state.servers?.filter((s) => s.id) || [],
    [state.servers]
  );

  return (
    <Page
      backButton="/admin/owners"
      title="Owners"
      options={
        <>
          {!isNew && (
            <Tooltip title="Delete Owner">
              <span>
                <IconButton
                  disabled={loading || isChanging}
                  color="error"
                  onClick={() => setDeleting(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      }
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <EditPageCard fullWidth error={error}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Box sx={{ mb: 2, ml: -2 }}>
                <Typography variant="h5" sx={{ ml: 2, mb: 1 }}>
                  {isNew ? 'New Owner' : data?.owner?.name}
                </Typography>
                <Divider />
              </Box>
              <TextField
                required
                disabled={isChanging}
                value={state.name || ''}
                label="Name"
                onChange={(e) => {
                  setState({ ...state, name: e.target.value });
                }}
                variant="outlined"
              />
              <TextField
                disabled={isChanging}
                value={state.email || ''}
                label="Email"
                onChange={(e) => setState({ ...state, email: e.target.value })}
                variant="outlined"
              />
              <TextField
                disabled={soLoading || isChanging}
                required
                select
                label="Sales Office"
                value={state.salesOfficeId || ''}
                onChange={(e) =>
                  setState({ ...state, salesOfficeId: e.target.value })
                }
              >
                {soData?.salesOffices.map((so) => (
                  <MenuItem key={so.id} value={so.id}>
                    {so.name}
                  </MenuItem>
                ))}
              </TextField>
              <Box sx={{ alignSelf: 'flex-end', mr: 2, mt: 2 }}>
                <LoadingButton
                  startIcon={<Save />}
                  variant="outlined"
                  loading={isChanging}
                  onClick={handleSave}
                  name="save_continue"
                  sx={{ mr: 2, mb: 2 }}
                >
                  Save & Continue
                </LoadingButton>
                <LoadingButton
                  startIcon={<Save />}
                  variant="contained"
                  onClick={handleSave}
                  loading={isChanging}
                  name="save"
                  sx={{ mr: 2, mb: 2 }}
                >
                  Save & Close
                </LoadingButton>
              </Box>
            </Grid>

            <Grid item xs={12} lg={8}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Servers
              </Typography>
              {state.servers?.map((server, index) => (
                <ServerEdit
                  key={server.id || index}
                  ownerId={data?.owner?.id}
                  disabled={isChanging}
                  qtyCompanies={qtyCompanies}
                  servers={serversList}
                  server={server}
                  setState={(newS) =>
                    setState({
                      ...state,
                      servers: state.servers?.map((s, i) =>
                        i === index ? newS : s
                      ),
                    })
                  }
                  removeServer={() =>
                    setState({
                      ...state,
                      servers: state.servers?.filter((_, i) => i !== index),
                    })
                  }
                />
              ))}

              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                sx={{ mt: 2, ml: 2 }}
                onClick={() =>
                  setState({
                    ...state,
                    servers: [
                      ...(state.servers ?? []),
                      {
                        name: `Server ${(state.servers?.length ?? 0) + 1}`,
                        companies: [{ name: 'Company 1', allocatedUsers: 1 }],
                        packageIds: [],
                      },
                    ],
                  })
                }
              >
                Add Server
              </Button>
            </Grid>
          </Grid>
        </EditPageCard>
      )}
      {!isNew && (
        <Dialog open={deleting} onClose={() => setDeleting(false)}>
          <DialogTitle>Delete {state.name}</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure that you want to delete this owner?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              autoFocus
              onClick={() => setDeleting(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={async () => {
                await remove({ variables: { id: params.id } });
                nav('/admin/owners');
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Page>
  );
};

export default EditOwnerPage;
