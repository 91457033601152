import { gql } from '@apollo/client';
import { whoAmIFragment } from 'lib/UserContext/queries';
import { yubiKeyFragment } from '../users/queries';

const profileFragment = gql`
  fragment Profile on User {
    ...WhoAmI
    email
    twoFactorEnabled
    yubiKeys {
      ...YubiKey
    }
  }
  ${whoAmIFragment}
  ${yubiKeyFragment}
`;

export const profileQuery = gql`
  query Profile {
    whoAmI {
      ...Profile
    }
  }
  ${profileFragment}
`;

export const profileUpdateMutation = gql`
  mutation ProfileUpdate($input: ProfileUpdateInput!) {
    profileUpdate(input: $input) {
      ...Profile
    }
  }
  ${profileFragment}
`;

export const addYubiKeyMutation = gql`
  mutation UserYubiKeyAdd($input: YubiKeyAddInput!) {
    userYubiKeyAdd(input: $input) {
      ...Profile
    }
  }
  ${profileFragment}
`;
