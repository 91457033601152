import { FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import * as QueryTypes from 'lib/gql/queryTypes';
import Page from '../../Page';
import AssignServerDialog from './AssignServerDialog';
import { clientConnectionSubscription, connectedClientsQuery } from './queries';
import {
  ClientConnectionsSubscription,
  ConnectedClientFragment,
  ConnectedClientsQuery,
  ConnectedClientsQueryVariables,
} from './types/queries';

export const ConnectedClientsPage: FC = () => {
  const [assignServer, setAssignServer] = useState<ConnectedClientFragment>();
  const { data, subscribeToMore, refetch } = useQuery<
    ConnectedClientsQuery,
    ConnectedClientsQueryVariables
  >(connectedClientsQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(
    () =>
      subscribeToMore<ClientConnectionsSubscription>({
        document: clientConnectionSubscription,
        updateQuery: (prev, { subscriptionData }) => {
          if (
            !subscriptionData.data ||
            !subscriptionData.data.clientConnections.event
          ) {
            return prev;
          }
          const newFeedItem = subscriptionData.data.clientConnections.event;

          if (
            newFeedItem.eventType ===
            QueryTypes.ClientConnectionEventType.Connected
          ) {
            return {
              clients: [newFeedItem.client, ...prev.clients],
            };
          } else {
            return {
              ...prev,
              clients: prev.clients.filter(
                (c) => c.id !== newFeedItem.client.id
              ),
            };
          }
        },
      }),
    [subscribeToMore]
  );

  const doClose = useCallback(
    async (changed = false) => {
      setAssignServer(undefined);
      if (changed) {
        await refetch();
      }
    },
    [refetch]
  );

  return (
    <Page title="Connected Clients">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Connected</TableCell>
              <TableCell>Ip Address</TableCell>
              <TableCell>Machine Id</TableCell>
              <TableCell>Machine Name</TableCell>
              <TableCell>Version</TableCell>
              <TableCell>Companies</TableCell>
              <TableCell>Existing Owner - Server</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.clients.map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  <Typography variant="body2">
                    {new Date(c.connectionTime).toLocaleDateString() +
                      ' ' +
                      new Date(c.connectionTime).toLocaleTimeString()}
                  </Typography>
                </TableCell>
                <TableCell>{c.ipAddress}</TableCell>
                <TableCell>
                  <Box display="flex">
                    <Tooltip title="Copy the Machine Id to the clipboard.">
                      <IconButton
                        sx={{ p: 0, mr: 1 }}
                        onClick={() =>
                          navigator.clipboard.writeText(c.machineId)
                        }
                      >
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    {c.machineId.slice(0, 10)}...
                  </Box>
                </TableCell>
                <TableCell>{c.name}</TableCell>
                <TableCell>{c.version}</TableCell>
                <TableCell>
                  <Box sx={{ maxHeight: 100, overflowY: 'auto' }}>
                    {c.companies && (
                      <ul>
                        {c.companies.map((company) => (
                          <li key={company.localId}>{company.name}</li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  {c.server && c.server?.owner.name + ' - ' + c.server?.name}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={() => setAssignServer(c)}
                  >
                    {c.server ? 'Reassign' : 'Assign'}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {data?.clients.length === 0 && (
              <TableRow>
                <TableCell colSpan={8}>
                  There are no connections to the server...
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <AssignServerDialog connectedClient={assignServer} onClose={doClose} />
    </Page>
  );
};

export default ConnectedClientsPage;
